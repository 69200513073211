<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Iniciar Sesión</h4>
                  <p class="mb-0">Ingresa tu email para iniciar sesión</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="onSubmit">
                    <div class="mb-3">
                      <argon-input 
                        type="email" 
                        placeholder="Email" 
                        v-model="user.email"
                        name="email" 
                        size="lg"
                        :valid="true" />
                    </div>
                    <div class="mb-3">
                      <argon-input type="password" placeholder="Contraseña" v-model="user.password" name="password" size="lg" :valid="true"/>
                    </div>
                    <argon-switch id="rememberMe">Recordarme</argon-switch>

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                      >Sign in</argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://img.freepik.com/vector-gratis/ilustracion-concepto-tablero-scrum_114360-1570.jpg?w=1800&t=st=1667927878~exp=1667928478~hmac=29676a62189cfad6a3ee098b559e87ec6b10954d4e64548cedb1cacbcc005b61');
          background-size: cover; margin-left: auto; background-position: center; background-size: 115%; background-repeat: no-repeat;"
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Plataforma Prestadores"</h4>
                <p
                  class="text-white position-relative"
                >Un lugar enfocado en facilitar la gestión de tu tiempo para trabajar con Clase Alpha.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import firebaseApp from 'firebase/compat/app'
import 'firebase/compat/auth';
import axios from 'axios'

  var firebaseConfig = {
    apiKey: "AIzaSyDt-wTgZwFqTSvUgBKusodp-yI2_ERIQ2k",
    authDomain: "joget-65a97.firebaseapp.com",
    projectId: "joget-65a97",
    storageBucket: "joget-65a97.appspot.com",
    messagingSenderId: "456306756171",
    appId: "1:456306756171:web:8476d8a7f0360737dcdbce",
    measurementId: "G-ESPKXDP6TT"
  }
// Initialize Firebase
  firebaseApp.initializeApp(firebaseConfig);
  const authfb = firebaseApp.auth()

export default {
  name: "signin",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
  },
  data: () => ({
    user: {
      switch: false,
      email: '',
      password: '',
      prueba: null
    },
    loading: false,
    datos: '',
    loader: '',
  }),
  created() {
    localStorage.clear();
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
      onSubmit () {
      this.loaderfunction(true)
      this.firebaseLogin()
      },
      loaderfunction(state){
        this.loader = this.$loading.show({
                    // Optional parameters
                    active: state,
                    canCancel: false,
                    color: '#006188',
                    transition: 'fade',
                    width: 115,
                    height: 115,
                    loader: 'dots',
                    backgroundColor: '#D1D1D1',
                    lockScroll: true,
                    isFullPage: true,
                    
                });
      },
      firebaseLogin () {
        this.loading = true
        console.log('preparado para el auth')
        authfb.signInWithEmailAndPassword(this.user.email.toLowerCase(), this.user.password).then((user) => {
          const firebaseUser = authfb.currentUser.providerData[0]
          console.log('raperos', firebaseUser)
          localStorage.setItem('user', JSON.stringify(firebaseUser))
          localStorage.setItem('uid', user.user.uid)
          localStorage.setItem('docId', user.user.uid)
          localStorage.setItem('email', user.user.email)
          localStorage.setItem('name', user.user.email)
          if (this.user.switch === false) {
            this.isRegistred()
          } else if (this.user.switch === true) {
            this.isAdminRegistred()
          }
        }).catch((err) => {
          if (err.code === 'auth/user-not-found') {
            // core.showSnackbar('error', 'These credentials do not match our records.')
            this.loading = false
          } else {
            // core.showSnackbar('error', err.message)
            this.loading = false
          }
        })
        
      },
      async habilidades () {
      const vm = this
      await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/prestadores_servicios_api?alegra_contacts.uid=' + localStorage.getItem('uid'), {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            // core.showSnackbar('error', 'consulta exitosa')
            localStorage.setItem('habilidades', JSON.stringify(res.data.data[0]))
            console.log('data de habilidades', res.data)
            console.log('data para route', vm.datos['uid'])
            vm.$router.push({ path: `/Dashboard/` })
            vm.loader.hide()
            // vm.userJoget.put()
          }
        })
        .catch(function (err) {
          console.log('error de consulta', err)
        })
    },
    async documentos () {
      const vm = this
      await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/0_0_1_documentos?id=' + JSON.parse(localStorage.getItem('userJoget'))['id_alegra'], {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            console.log('respuestas', res.data)
            localStorage.setItem('documentos', JSON.stringify(res.data.data[0]))
            var arrayDeCadenas = res.data.data[0].foto.split('"')
            var URL = process.env.VUE_APP_API_BASE_URL + arrayDeCadenas[1]
            localStorage.setItem('URL', URL)
            console.log('url separada', URL)
            vm.habilidades()
            // vm.userJoget.put()
          }
        })
        .catch(function (err) {
          console.log('error de consulta', err)
        })
    },
    async isRegistred () {
      const vm = this
      // db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts')
      // .where('email', '==', this.user.email.toLowerCase())
      // .get()
      // .then((querySnapshot) =>
      this.consult()
        .then(function (querySnapshot) {
          console.log('query', querySnapshot)
          if (!vm.datos['uid']) {
            localStorage.removeItem('user')
            localStorage.removeItem('access_token')
            firebaseApp.auth().signOut().then(() => {
              // core.showSnackbar('error', 'No está registrado como usuario')
            }).catch((error) => {
              console.log(error)
            })
          } else {
            // console.log('Si está')
            // console.log('userUID', vm.datos.uid)
            // localStorage.setItem('name', vm.datos.email)
            // this.$router.push({ path: '/superAdmin.sing-in' })
            vm.documentos()
          }
        }).catch((error) => {
          console.log('Error obteniendo la informacion: ', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async consult () {
      const vm = this
      // await axios.get('https://clasealpha.cloud.joget.com/jw/api/list/list_alegra_contacts?uid=7ThF7hLwKgOCAHiQaGPvLa3Ox1o2', {
      await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/2_0_1_consulta_externa?uid=' + localStorage.getItem('uid'), {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          console.log('respuesta', res.data)
          if (res.status === 200) {
            if (res.data.data.length === 0) {
              // core.showSnackbar('error', 'Usuario no Registrado o no Valido')
            }
            vm.datos = res.data.data[0]
            var data = res.data.data
            console.log('consulta usuario especifico', data)
            localStorage.setItem('userJoget', JSON.stringify(res.data.data[0]))
            return data
          }
        })
        .catch(function (err) {
          console.log('error usuaroi especifico', err)
        })
    }
    }
};
</script>
