<template>
    <div class="table-responsive p-0">
        <table class="table">
            <thead>
                <th v-for="th in theads" :key="th + index" class="text-uppercase text-secondary text-xs font-weight-bolder">
                {{th}}</th>
            </thead>
            <tbody class="list">
                <tr v-for="item in data" :key='item'>
                    <td class="align-middle">{{item.c_full_name}}</td>
                    <td class="align-middle">{{item.funcion}}</td>
                    <td class="align-middle">
                        <font-awesome-icon v-if="item.confirm=='No'" icon="fa-solid fa-circle-xmark" color="#f5365c"/>
                        <font-awesome-icon v-if="item.confirm=='Si'" icon="fa-solid fa-check" color="#2dce89"/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "prestadores-table",
    data(){
        return{
            theads: ['Prestador','Función','Confirmado'],
        }
    },
    props:{
        data:{
            type: Array,
            // eslint-disable-next-line
        }
    }
}
</script>