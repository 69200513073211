<template>
  <ArgonAlert v-if="showAlert" @close="resetShowAlert" :color="alertType" icon="ni ni-like-2 ni-lg" :time=4000
      :dismissible=true>
      {{ alertMsg }}
   </ArgonAlert>
  <div class="modal fade" id="deleteDateModal" tabindex="-1" aria-labelledby="Eliminar Disponibilidad" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Eliminar Disponibilidad</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h6>Seguro deseas eliminar esta dispinibilidad?</h6>
          <p>{{ selectedDate.fecha }}</p>
        </div>
        <div class="modal-footer">
          <ArgonButton color="success" type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="closeModalDM">Cerrar</ArgonButton>
          <ArgonButton color="warning" type="button" class="btn btn-primary" @click="this.deleteDates()">Eliminar</ArgonButton>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-if="!dataloaded">
    <AviabilityLoader></AviabilityLoader>
  </div>
    <div class="card" v-if="dataloaded">
      <div class="p-3 pb-0 card-header">
        <div class="row">
          <div class="col-8 d-flex">
          <h6 class="mb-0">{{ cardTitle }}</h6>
        </div>
          <div class="col-4 d-flex">
            <argon-button color="success" size="sm" class="ms-auto" @click="showDuleModal = true">
              Añadir
            </argon-button>
            <ScheduleModal :show="showDuleModal" @close="showDuleModal = false" @update="updateTiempos"/>
          </div>
        </div>
        <hr class="horizontal dark mb-1" />
      </div>
      <div class="p-3 card-body">
        <div class="d-flex justify-content-center" v-if="!this.tiempos.length > 0">
          <h6> No hay disponibilidad aún, añade una!</h6>
        </div>
        <ul class="list-group" :class="this.$store.state.isRTL ? 'pe-0' : ''">
          <li 
            v-for="(input, index) in tiempos" :key="index"
            class="border-0 list-group-item d-flex justify-content-between border-radius-lg"
            :class="this.$store.state.isRTL ? 'pe-0' : 'ps-0'"
    
          >
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <h6 class="mb-0 text-sm text-dark">{{input.fecha}}</h6>
              </div>
            </div>
            <button type="button" class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark" data-bs-toggle="modal" data-bs-target="#deleteDateModal" @click="this.selectedDate=input">
              <font-awesome-icon icon="fa-solid fa-trash-can" size="lg" />
            </button>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import ArgonButton from "@/components/ArgonButton.vue";
import ScheduleModal from './ScheduleModal.vue';
import { ref } from 'vue';
import AviabilityLoader from '../../components/Loaders/AviabilityLoader.vue';
import ArgonAlert from "../../components/ArgonAlert.vue";

export default {
  name: "availability-card",
  components:{
    ArgonButton,
    ScheduleModal,
    AviabilityLoader,
    ArgonAlert,
  },  
  props: {
    cardTitle: {
      type: String,
      default: "Disponibilidad",
    },
    title: {
      type: String,
      default: "Asamblea Virtual",
    },
  },
  data(){
    return{
      tiempos: '',
      userJoget: JSON.parse(localStorage.getItem('userJoget')),
      dataloaded: false,
      selectedDate:'',
      showAlert: false,
      alertMsg: null,
      alertType: null,
    }
  },
  beforeMount(){
    this.actualizarFechas();
  },
  mounted(){
    this.closeModalDM = document.getElementById("closeModalDM")

  },
  methods:{
    updateTiempos(data){
      //const vm = this
      this.tiempos = data
      
    },
    resetShowAlert(varAlert) {
         this.showAlert = varAlert;
      },
    showAlertMethod(msg, type) {
         this.alertType = type;
         this.alertMsg = msg;
         this.showAlert = true;
    },
    async actualizarFechas () {
      const vm = this
      await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/023_disponibilidad2?id_alegra=' + this.userJoget['id_alegra'], {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          console.log('respuesta tiempos', res.data)
          if (res.status === 200) {
            console.log('true', 'comnsulta exitosa') //core
            vm.tiempos = res.data.data
            setTimeout(() => {
              vm.dataloaded = true
            }, "1000")
          }
        })
        .catch(function (err) {
          console.log('error de consulta', err)
        })
    },
    async deleteDates(){
      const vm = this
      if(await vm.checkDate()){
        vm.closeModalDM.click()
        vm.showAlertMethod(
                     "Para esta fecha tienes una asamblea agendada",
                     "warning"
        )
        
        return
      }
      await axios.delete(process.env.VUE_APP_API_BASE_URL+'/jw/api/form/023_disponibilidad2/' + vm.selectedDate.id, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            console.log('true', 'eliminacion exitosa') //core
            vm.actualizarFechas()
            vm.closeModalDM.click()
            vm.showAlertMethod(
                     "Se eliminó correctamente la disponibilidad",
                     "success"
            );
          }
        })
        .catch(function (err) {
          console.log('error de eliminacion', err)
          vm.showAlertMethod(
                     "Se produjo un error, intentalo nuevamente",
                     "warning"
          );
        })
    },
    async checkDate(){
      let shortDate = this.selectedDate.fecha.split(' ')
      return await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/1602_agenda_operarios_api?id_alegra='+this.userJoget['id_alegra']+'&fecha=' + shortDate[0], {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            console.log(res.data, ' no se puede eliminar') //core
            if(res.data.data.length > 0){
              return true
            }
            return false
          }
        })
        .catch(function (err) {
          console.log('error en la consulta de verificacion fecha agendada', err)
        })
    }
    
  },
  setup(){
    let showDuleModal = ref(false);
    return {showDuleModal}
  }
};

</script>
