<script>
  import { ContentLoader } from "vue-content-loader"

  export default {
    components: { ContentLoader }
  }
</script>

<template>
    <content-loader
      viewBox="0 0 605 630"
      :speed="1.5"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="5" y="5" rx="5" ry="5" width="595" height="82" /> 
      <rect x="5" y="95" rx="5" ry="5" width="595" height="82" /> 
      <rect x="5" y="185" rx="5" ry="5" width="595" height="82" /> 
      <rect x="5" y="275" rx="5" ry="5" width="595" height="82" /> 
      <rect x="5" y="365" rx="5" ry="5" width="595" height="82" /> 
      <rect x="5" y="455" rx="5" ry="5" width="595" height="82" /> 
      <rect x="5" y="545" rx="5" ry="5" width="595" height="82" />
    </content-loader>
  </template>
  
  
