<template>
    <div v-if="show" class="modal-backdrop" tabindex="-1">
        <div class="modal-dialog" >
            <div class="modal-content">
                <header class="modal-header">
                    <div>
                        <p class="modal-title">Ingresa tu Disponibilidad</p>
                    </div>
                </header>
                <div class="modal-body">
                    <div class="row">
                        <div class="btn-group">
                            <ArgonButton size="sm" color="success"  class="ms-auto" @click="changeWeek('prev')">Prev</ArgonButton>
                            <ArgonButton size="sm" color="error" class="ms-auto" variant="outline-secondary">{{startMonth}} {{date.weekStart.day}} - {{endMonth}} {{date.weekEnd.day}}, {{weekDate.year}}</ArgonButton>
                            <ArgonButton size="sm" color="success" class="ms-auto" @click="changeWeek('next')">Sig</ArgonButton>
                        </div>
                    </div>
                    <div>
                        <table class="table justify-content-center table-hover">
                            <thead class="caption-top table-active">
                                <th>Dias</th>
                                <th>Hora Inicio</th>
                            </thead>
                            <tbody>
                                <tr v-for="data in dates" :key="data">
                                    <td>
                                        {{data.date}}
                                    </td>
                                        <div class="row">
                                            <div class="col-8" v-if="data.available">
                                                <span v-if="!data.editable">{{ formatAMPM(data.start) }}</span>
                                                <input type="time" v-else v-model="data.start" class="form-control"/>
                                                <ArgonButton color="success" size="sm" @click="edit(data)" v-if="!data.editable">Editar</ArgonButton>
                                                <ArgonButton color="success" size="sm" @click="submit(data)" v-else>Ok</ArgonButton>
                                            </div>
                                            <div v-else>
                                                <span v-if="!data.editable" class="text-danger">Sin asignar</span>
                                                <input type="time" v-else v-model="data.start" class="form-control" />
                                                <ArgonButton color="success" size="sm" @click="edit(data)" v-if="!data.editable">Agregar</ArgonButton>
                                                <ArgonButton color="success" size="sm" @click="submit(data)" v-else>Ok</ArgonButton>
                                            </div>
                                        </div>
                                    <td>
                                        <div class="row">
                                            <div class="col-8">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <footer class="modal-footer">
                    <div class="">
                        <ArgonButton color="success" size="sm" class="ms-auto" @click="$emit('close')">
                            Cerrar
                        </ArgonButton>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from '../../components/ArgonButton.vue';
const { DateTime } = require('luxon');
import axios from 'axios';

// function formatNumber (number) {
//   return Math.floor(number)
//     .toString()
//     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
// }
export default {
    name: 'Schedule-Modal',
    props:{
        show: Boolean,
    },
    components: {
        ArgonButton,
    },
    watch: {
        rowDataCard (newValue) {
            if (newValue.length >= 1) {
                this.getWeekDates(this.weekDate)
            } else {
                this.$emit('load-success', { type: false })
            }
        },
    },
    computed: {
        startMonth () {
        return this.months[this.date.weekStart.month - 1]
        },
        endMonth () {
        return this.months[this.date.weekEnd.month - 1]
        },
        formatedDate () {
        return this.formatDate(this.weekDate)
        }
    },
    mounted () {
        //core.index()
        //this.getAssemblies()
        // this.changeWeek('prev')
        this.getWeekDates(this.weekDate)
        //this.habilidades()
    },
    data(){
        return{
            weekDate: DateTime.now().c,
            timeValue:'',
            date: {
                day: 0,
                weekStart: {},
                weekEnd: {},
                today: {
                day: 0,
                month: 0,
                year: 0
                },
                weekOfYear: 0
            },
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
            boards: [
                {
                id: 1,
                title: 'Lunes',
                date: '',
                color: 'bg-primary',
                available: {
                    presencial: 10,
                    virtual: 20,
                    mixta: 10,
                    cotizacion: 0
                },
                list: []
                },
                {
                id: 2,
                title: 'Martes',
                date: '',
                color: 'bg-success',
                available: {
                    presencial: 10,
                    virtual: 20,
                    mixta: 10,
                    cotizacion: 0
                },
                list: []
                },
                {
                id: 3,
                title: 'Miércoles',
                date: '',
                color: 'bg-info',
                available: {
                    presencial: 10,
                    virtual: 20,
                    mixta: 10,
                    cotizacion: 0
                },
                list: [
                ]
                },
                {
                id: 4,
                title: 'Jueves',
                date: '',
                color: 'bg-warning',
                available: {
                    presencial: 10,
                    virtual: 20,
                    mixta: 10,
                    cotizacion: 0
                },
                list: []
                },
                {
                id: 5,
                title: 'Viernes',
                date: '',
                color: 'bg-danger',
                available: {
                    presencial: 10,
                    virtual: 20,
                    mixta: 10,
                    cotizacion: 0
                },
                list: []
                },
                {
                id: 6,
                title: 'Sábado',
                date: '',
                color: 'bg-danger',
                available: {
                    presencial: 10,
                    virtual: 20,
                    mixta: 10,
                    cotizacion: 0
                },
                list: []
                },
                {
                id: 7,
                title: 'Domingo',
                date: '',
                color: 'bg-danger',
                available: {
                    presencial: 10,
                    virtual: 20,
                    mixta: 10,
                    cotizacion: 0
                },
                list: []
                }
            ],
            rowDataCard: null,
            user: {
                staff: {},
                address: {
                address: '',
                city: '',
                country: '',
                department: '',
                neighborhood: '',
                sector: ''
                },
                type: '',
                name: '',
                nameAdmin: '',
                email: '',
                url: '',
                phonePrimary: '',
                identificationObject: {
                number: '',
                type: '',
                dv: ''
                },
                profile_image: ''
            },
            userJoget: JSON.parse(localStorage.getItem('userJoget')),
            dates: [],
            columns: [
                { label: 'Fecha', key: 'date', class: 'text-left' },
                { label: 'Hora inicio', key: 'start', class: 'text-left' }
            ],
            agenda_operarios: [],
        }
    },
    methods:{
        async changeWeek (type) {
            for (let index2 = 0; index2 < this.boards.length; index2++) {
                this.boards[index2].list = await []
                this.boards[index2].available = {
                presencial: 10,
                virtual: 20,
                mixta: 10,
                cotizacion: 0

                }
        }
        console.log('listas vacias')
            if (type === 'next') {
                this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).plus({ days: 7 }).c
                console.log('add', this.weekDate)
                this.getWeekDates(this.weekDate)
            }
            if (type === 'prev') {
                this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).minus({ days: 7 }).c
                this.getWeekDates(this.weekDate)
                console.log('minus', this.weekDate)
            }
        },
        async getWeekDates (myDate) {
        // console.log('boards lenght', this.boards.length)
        var habilidades = JSON.parse(localStorage.getItem('habilidades'))
        this.userJoget.name = habilidades['full_name']
        for (let index2 = 0; index2 < this.boards.length; index2++) {
            this.boards[index2].list = await []
            // console.log('boards lenght', this.boards)
            this.boards[index2].available = {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
            }
            this.dates = []
        }
        this.date.today.day = DateTime.now.day
        this.date.today.month = DateTime.now.month
        this.date.today.year = DateTime.now.year
        this.date.weekStart = await DateTime.local(myDate.year, myDate.month, myDate.day).startOf('week').c
        this.date.weekEnd = DateTime.local(myDate.year, myDate.month, myDate.day).endOf('week').c
        this.date.weekOfYear = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).weekNumber
        for (let index = 0; index < this.boards.length; index++) {
            this.boards[index].date = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day
        }
        if (this.rowDataCard !== null) { this.getCardsInfo() }
        this.providersDatesInit(this.date.weekOfYear)
        this.agendaOperarios()
        },
        providersDatesInit (weekNumber) {
        console.log('entré al init', this.user.schedule)
        if (this.user.schedule != null) {
            console.log('tiene schedule')
            if (this.user.schedule[`week${weekNumber}`] !== null && this.user.schedule[`week${weekNumber}`] !== undefined) {
            console.log('tiene semana', weekNumber)
            this.userAvailability = this.user.schedule[`week${weekNumber}`]
            console.log('disponibilidad', this.userAvailability, this.date.weekOfYear)
            for (let index = 0; index < 7; index++) {
                var variableIndex = index + 1
                this.dates.push({
                fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
                date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
                start: this.userAvailability[variableIndex].start,
                available: this.userAvailability[variableIndex].available,
                editable: false,
                hasPrevValue: true,
                totalServices: this.userAvailability[variableIndex].totalServices,
                totalUsers: this.userAvailability[variableIndex].totalUsers
                })
            }
            } else {
            for (let index = 0; index < 7; index++) {
                this.dates.push({
                fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
                date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
                start: '',
                available: false,
                editable: false,
                hasPrevValue: false,
                totalServices: 0,
                totalUsers: 0
                })
            }
            console.log('No tiene Semana', weekNumber)
            }
        } else {
            for (let index = 0; index < 7; index++) {
            this.dates.push({
                fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
                date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
                start: '',
                available: false,
                editable: false,
                hasPrevValue: false,
                totalServices: 0,
                totalUsers: 0
            })
            }
            console.log('No tiene Schedule', weekNumber)
        }
        },
        async agendaOperarios () {
            const vm = this
            this.actualizarFechas()
            var newday
            var newmonth
            console.log('Dia Inicio', vm.date.weekStart.day)
            console.log('Mes Inicio', vm.date.weekStart.month)
            console.log('Año Inicio', vm.date.weekStart.year)
            if (vm.date.weekStart.day < 10) {
                newday = '0' + vm.date.weekStart.day
            } else {
                newday = vm.date.weekStart.day
            }
            if (vm.date.weekStart.month < 10) {
                newmonth = '0' + vm.date.weekStart.month
            } else {
                newmonth = vm.date.weekStart.month
            }
            var fecha1 = newmonth + '/' + newday + '/' + vm.date.weekStart.year
            console.log('fecha1', fecha1)
            for (let index = 0; index < 7; index++) {
                var fecha2 = new Date(fecha1)
                console.log('fecha2_primero', fecha2)
                fecha2.setDate(fecha2.getDate() + index)
                var day = `0${fecha2.getDate()}`.slice(-2)
                var month = `0${fecha2.getMonth() + 1}`.slice(-2)
                var resultado = day + '-' + month + '-' + fecha2.getFullYear()
                console.log('fecha2', resultado)
                await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/1602_agenda_operarios_api?fecha=' + resultado + '&c_full_name=' + vm.userJoget.name, {
                responseType: 'json',
                headers: {
                    accept: 'application/json',
                    api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
                    api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
                }
                })
                .then(function (res) {
                    console.log('respuesta Agenda Operarios', res.data.data[0])
                    vm.agenda_operarios[index] = {
                    c_full_name: '',
                    cot_number: '',
                    fecha: '',
                    funcion: '',
                    hora: ''
                    }
                    if (res.data.data[0]) {
                    vm.agenda_operarios[index] = res.data.data[0]
                    vm.boards[index].list.push(res.data.data[0])
                    } else {
                    vm.agenda_operarios[index] = {}
                    }
                    // vm.userJoget['alegra_contacts.phone'] = vm.user.phonePrimary
                    // vm.userJoget['alegra_contacts.address'] = vm.user.address.address
                    // vm.makeToast('success', 'guardado exitoso')
                })
                .catch(function (err) {
                    console.log('error de almacenaje', err)
                    // vm.makeToast('danger', 'Problemas al guardar.')
                })
            }
        },
        async actualizarFechas () {
        const vm = this
        await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/023_disponibilidad2?id_alegra=' + this.userJoget['id_alegra'], {
            responseType: 'json',
            headers: {
            accept: 'application/json',
            api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
            api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
            }
        })
            .then(function (res) {
            console.log('respuesta tiempos', res.data)
            if (res.status === 200) {
                console.log('true', 'comnsulta exitosa') //core
                //vm.tiempos = res.data.data
                vm.$emit('update', res.data.data)
            }
            })
            .catch(function (err) {
            console.log('error de consulta', err)
            })
        },
        formatAMPM (d) {
        var strTime = d
        if (d !== '-') {
            const hour = d.split(':')
            var hours = hour[0]
            var minutes = hour[1]
            var ampm = hours >= 12 ? 'pm' : 'am'
            hours = hours % 12
            hours = hours === 0 ? 12 : hours // the hour '0' should be '12'
            hours = hours < 10 ? '0' + hours : hours
            strTime = hours + ':' + minutes + ' ' + ampm
        }
        return strTime
        },
        add () {
        // const obj = this.default()
        // const ale = this.alegra()
        this.dates.push(this.default())
        // this.itemsAlegra.push(this.alegra())
        },
        edit (item) {
        console.log('estoy editando', item)
        item.editable = true
        },
        submit (item) {
        // 17-09-2022 12:00 am
        this.hora = this.formatAMPM(item.start)
        if (item.fullDate.day < 10) {
            console.log('dia', '0' + item.fullDate.day)
            this.dia = '0' + item.fullDate.day
        } else {
            console.log('dia', item.fullDate.day)
            this.dia = item.fullDate.day
        }
        if (item.fullDate.month < 10) {
            console.log('mes', '0' + item.fullDate.month)
            this.month = '0' + item.fullDate.month
        } else {
            console.log('mes', item.fullDate.month)
            this.month = item.fullDate.month
        }
        var fecha1 = this.dia + '-' + this.month + '-' + item.fullDate.year
        var fecha = this.dia + '-' + this.month + '-' + item.fullDate.year + ' ' + this.hora
        console.log('fecha', fecha)
        console.log('hora', this.hora)
        this.editFecha(fecha1, fecha)
        console.log('guardé', item)
        item.editable = false
        item.hasPrevValue = true
        if (item.start !== '') { item.available = true }
        },
        async editFecha (fecha2, fecha1) {
        const vm = this
        await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/023_disponibilidad2?id_alegra=' + this.userJoget['id_alegra'] + '&fecha=' + fecha2, {
            responseType: 'json',
            headers: {
            accept: 'application/json',
            api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
            api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
            }
        })
            .then(function (res) {
            console.log('respuesta', res.data)
            if (res.status === 200) {
                console.log('true', 'comnsulta exitosa') //core 
                // vm.scheduleModal = false
                if (res.data.total > 0) {
                console.log('hacer update', res.data.data[0].id)
                var id1 = res.data.data[0].id
                vm.updateFecha(fecha1, id1)
                } else {
                console.log('agregar nuevo')
                vm.createFecha(fecha1)
                }
                // vm.$forceUpdate()
            }
            })
            .catch(function (err) {
            console.log('error de almacenaje', err)
            })
        },
        async createFecha (fecha1) {
      const vm = this
      var dispojoget = {
        id_alegra: this.userJoget['id_alegra'],
        fecha: fecha1
      }
      console.log('dispojoget', dispojoget)
      await axios.post(process.env.VUE_APP_API_BASE_URL+'/jw/api/form/023_disponibilidad2', dispojoget, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          console.log('respuesta', res.data)
          if (res.status === 200) {
            console.log('true', 'guardado exitoso') //core
            // vm.scheduleModal = false
            vm.actualizarFechas()
          }
        })
        .catch(function (err) {
          console.log('error de almacenaje', err)
        })
        },
        async updateFecha (fecha1, id1) {
      const vm = this
      var dispojoget = {
        id: id1,
        id_alegra: this.userJoget['id_alegra'],
        fecha: fecha1
      }
      console.log('dispojoget update Fecha', dispojoget)
      await axios.put(process.env.VUE_APP_API_BASE_URL+'/jw/api/form/023_disponibilidad2', dispojoget, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          console.log('respuesta', res.data)
          if (res.status === 200) {
            console.log('true', 'actualizacion exitosa') //core
            // vm.scheduleModal = false
            // vm.$forceUpdate()
            vm.actualizarFechas()
          }
        })
        .catch(function (err) {
          console.log('error de almacenaje', err)
        })
        },
    },
    
    
}

</script>