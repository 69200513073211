<template>
  <main>
    <div class="container-fluid">
      <div
        class="page-header min-height-300"
        style="
          background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
          margin-right: -24px;
          margin-left: -34%;
        "
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <img
                  :src="URL"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                />
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{user.name}}</h5>
                <p class="mb-0 font-weight-bold text-sm">Prestador de servicios</p>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="px-0 py-1 mb-0 nav-link active"
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="true"
                    >
                      <svg
                        class="text-dark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 42 42"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2319.000000, -291.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(603.000000, 0.000000)">
                                <path
                                  class="color-background"
                                  d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                                />
                                <path
                                  class="color-background"
                                  d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                                  opacity="0.7"
                                />
                                <path
                                  class="color-background"
                                  d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                                  opacity="0.7"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span class="ms-1">Perfil</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showSetup" class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Editar Perfil</p>
                <argon-button color="success" size="sm" class="ms-auto"
                  >Configuración</argon-button
                >
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Información de Usuario</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Username</label
                  >
                  <argon-input type="text" v-model:value="user.username" />
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Email</label
                  >
                  <argon-input type="email" v-model:value="user.email" />
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Nombres</label
                  >
                  <input class="form-control" type="text" v-model="user.name" />
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Apellidos</label
                  >
                  <argon-input type="text" v-model:value="user.lasName" />
                </div>
              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Informacion de Contacto</p>
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Dirección</label
                  >
                  <argon-input
                    type="text"
                    v-model:value="user.address"
                  />
                </div>
                <div class="col-md-3">
                  <label for="example-text-input" class="form-control-label"
                    >Ciudad</label
                  >
                  <argon-input type="text" v-model:value="user.city" />
                </div>
                <div class="col-md-3">
                  <label for="example-text-input" class="form-control-label"
                    >Departamento</label
                  >
                  <argon-input type="text" v-model:value="user.department" />
                </div>
                <div class="col-md-3">
                  <label for="example-text-input" class="form-control-label"
                    >NIT</label
                  >
                  <argon-input type="text" v-model:value="user.nit" />
                </div>
                <div class="col-md-3">
                  <label for="example-text-input" class="form-control-label"
                    >Celular</label
                  >
                  <argon-input type="text" v-model:value="user.phone" />
                </div>
              </div>
              <hr class="horizontal dark" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDocuments" class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Editar Documentos</p>
              </div><br>
              Foto de Perfil
              <div class="mt-3 col-md-6">
                  <div class="row">
                    <div class="col-1">
                      <label for="example-text-input" class="form-control-label"
                        >Foto</label>
                    </div>
                    <div class="col-11"><i class="fas fa-file-csv mr-2"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <a v-if="hasFoto.status" class="text-success">Foto Cargada </a>
                      <a v-else class="text-danger">Pendiente </a>
                      <i v-if="!hasFoto.status" class="fas fa-ban fa-xs text-danger"></i>
                      <i v-else class="fas fa-check fa-xs text-success"></i>
                    </div>
                    <div class="col-8">
                      <argon-button v-if="hasFoto.status" variant="outline" color="success" size="sm" class="ml-2 hoverSpan" @click="resetDocument('Foto', 'edit')">Cambiar</argon-button>
                    </div>
                  </div>
                  <input v-if="!hasFoto.status"  class="form-control" type="file" ref="foto" accept=".jpg,.gif,.png,.jpeg" />
                  <div class="mt-3 d-flex align-items-end justify-content-end">
                    <button v-if="!hasFoto.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('foto')" >Subir</button>
                  </div>
                </div>
                <hr class="horizontal dark" />
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Documentos Personales</p>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="example-text-input" class="form-control-label">Hoja de Vida</label>
                    </div>
                    <div class="col-9"><i class="fas fa-file-csv mr-2"></i></div>
                  </div>
                    <div class="row">
                      <div class="col-4">
                        <a v-if="hasCV.status" class="text-success">{{ hasCV.name }} </a>
                        <a v-else class="text-danger">Pendiente </a>
                        <i v-if="!hasCV.status" class="fas fa-ban fa-xs text-danger"></i>
                        <i v-else class="fas fa-check fa-xs text-success"></i>
                      </div>
                      <div class="col-8">
                      <argon-button v-if="hasCV.status" variant="outline" color="success" size="sm" class="ml-2 hoverSpan" @click="resetDocument('CV', 'edit')">Cambiar</argon-button>
                    </div>
                    </div>
                    <input v-if="!hasCV.status"  class="form-control" type="file" ref="hoja_vida" accept="application/pdf" />
                    <div class="mt-3 d-flex align-items-end justify-content-end">
                      <button v-if="!hasCV.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('hoja_vida')" >Subir</button>
                    </div>
                </div><br>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-2">
                      <label for="example-text-input" class="form-control-label"
                        >Cedula</label
                      >
                    </div>
                    <div class="col-10"><i class="fas fa-id-card mr-2"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <a v-if="hasCC.status" class="text-success">{{ hasCC.name }} </a>
                      <a v-else class="text-danger">Pendiente </a>
                      <i v-if="!hasCC.status" class="fas fa-ban fa-xs text-danger"></i>
                      <i v-else class="fas fa-check fa-xs text-success"></i>
                    </div>
                    <div class="col-8">
                      <argon-button v-if="hasCC.status" variant="outline" color="success" size="sm" class="ml-2" @click="resetDocument('CC', 'edit')">Cambiar</argon-button>
                    </div>
                  </div>
                  <div v-if="!hasCC.status" class="input-group mb-3"></div>
                  <input v-if="!hasCC.status"  class="form-control" type="file" ref="cedula" accept="application/pdf" />
                  <div class="mt-3 d-flex align-items-end justify-content-end">
                    <button v-if="!hasCC.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('cedula')" >Subir</button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="example-text-input" class="form-control-label"
                        >Certificado EPS</label
                      >
                    </div>
                    <div class="col-9"><i class="fas fa-medkit mr-2"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <a v-if="hasEPS.status" class="text-success">{{ hasEPS.name }} </a>
                      <a v-else class="text-danger">Pendiente </a>
                      <i v-if="!hasEPS.status" class="fas fa-ban fa-xs text-danger"></i>
                      <i v-else class="fas fa-check fa-xs text-success"></i>
                    </div>
                    <div class="col-8">
                      <argon-button v-if="hasEPS.status" variant="outline" color="success" size="sm" class="ml-2" @click="resetDocument('EPS', 'edit')">Cambiar</argon-button>
                    </div>
                  </div>
                  <div v-if="!hasEPS.status" class="input-group mb-3"></div>
                  <input v-if="!hasEPS.status"  class="form-control" type="file" ref="eps" accept="application/pdf" />
                  <div class="mt-3 d-flex align-items-end justify-content-end">
                    <button v-if="!hasEPS.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('eps')" >Subir</button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-4">
                      <label for="example-text-input" class="form-control-label"
                        >Certificado Pension</label
                      >
                    </div>
                    <div class="col-8"><i class="fas fa-university mr-2"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <a v-if="hasCP.status" class="text-success">{{ hasCP.name }} </a>
                      <a v-else class="text-danger">Pendiente </a>
                      <i v-if="!hasCP.status" class="fas fa-ban fa-xs text-danger"></i>
                      <i v-else class="fas fa-check fa-xs text-success"></i>
                    </div>
                    <div class="col-8">
                      <argon-button v-if="hasCP.status" variant="outline" color="success" size="sm" class="ml-2" @click="resetDocument('CP', 'edit')">Cambiar</argon-button>
                    </div>
                  </div>
                  <div v-if="!hasCP.status" class="input-group mb-3"></div>
                  <input v-if="!hasCP.status"  class="form-control" type="file" ref="pension" accept="application/pdf" />
                  <div class="mt-3 d-flex align-items-end justify-content-end">
                    <button v-if="!hasCP.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('pension')" >Subir</button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-4">
                      <label for="example-text-input" class="form-control-label"
                        >Certificado Bancario</label
                      >
                    </div>
                    <div class="col-8"><i class="fas fa-money-check-alt mr-2"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <a v-if="hasCB.status" class="text-success">{{ hasCB.name }} </a>
                      <a v-else class="text-danger">Pendiente </a>
                      <i v-if="!hasCB.status" class="fas fa-ban fa-xs text-danger"></i>
                      <i v-else class="fas fa-check fa-xs text-success"></i>
                    </div>
                    <div class="col-8">
                      <argon-button v-if="hasCB.status" variant="outline" color="success" size="sm" class="ml-2" @click="resetDocument('CB', 'edit')">Cambiar</argon-button>
                    </div>
                  </div>
                  <div v-if="!hasCB.status" class="input-group mb-3"></div>
                  <input v-if="!hasCB.status"  class="form-control" type="file" ref="bancario" accept="application/pdf" />
                  <div class="mt-3 d-flex align-items-end justify-content-end">
                    <button v-if="!hasCB.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('bancario')" >Subir</button>
                  </div>
                </div><br>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-1">
                      <label for="example-text-input" class="form-control-label"
                        >RUT</label
                      >
                    </div>
                    <div class="col-11"><i class="fas fa-passport mr-2"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <a v-if="hasRUT.status" class="text-success">{{ hasRUT.name }} </a>
                      <a v-else class="text-danger">Pendiente </a>
                      <i v-if="!hasRUT.status" class="fas fa-ban fa-xs text-danger"></i>
                      <i v-else class="fas fa-check fa-xs text-success"></i>
                    </div>
                    <div class="col-8">
                      <argon-button v-if="hasRUT.status" variant="outline" color="success" size="sm" class="ml-2" @click="resetDocument('RUT', 'edit')">Cambiar</argon-button>
                    </div>
                  </div>
                  <div v-if="!hasRUT.status" class="input-group mb-3"></div>
                  <input v-if="!hasRUT.status"  class="form-control" type="file" ref="rut" accept="application/pdf" />
                  <div class="mt-3  d-flex align-items-end justify-content-end">
                    <button v-if="!hasRUT.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('rut')" >Subir</button>
                  </div>
                </div><br>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="example-text-input" class="form-control-label"
                        >Tatamiento de datos</label
                      >
                    </div>
                    <div class="col-8"><i class="fas fa-passport mr-2"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <a v-if="hasTrd.status" class="text-success">{{ hasTrd.name }} </a>
                      <a v-else class="text-danger">Pendiente </a>
                      <i v-if="!hasTrd.status" class="fas fa-ban fa-xs text-danger"></i>
                      <i v-else class="fas fa-check fa-xs text-success"></i>
                    </div>
                    <div class="col-8">
                      <argon-button v-if="hasTrd.status" variant="outline" color="success" size="sm" class="ml-2" @click="resetDocument('Trd', 'edit')">Cambiar</argon-button>
                    </div>
                  </div>
                  <div v-if="!hasTrd.status" class="input-group mb-3"></div>
                  <input v-if="!hasTrd.status"  class="form-control" type="file" ref="tratamiento_datos" accept="application/pdf" />
                  <div class="mt-3  d-flex align-items-end justify-content-end">
                    <button v-if="!hasTrd.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('tratamiento_datos')" >Subir</button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="example-text-input" class="form-control-label"
                        >Contrato</label
                      >
                    </div>
                    <div class="col-8"><i class="fas fa-passport mr-2"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <a v-if="hasCont.status" class="text-success">{{ hasCont.name }} </a>
                      <a v-else class="text-danger">Pendiente </a>
                      <i v-if="!hasCont.status" class="fas fa-ban fa-xs text-danger"></i>
                      <i v-else class="fas fa-check fa-xs text-success"></i>
                    </div>
                    <div class="col-8">
                      <argon-button v-if="hasCont.status" variant="outline" color="success" size="sm" class="ml-2" @click="resetDocument('Cont', 'edit')">Cambiar</argon-button>
                    </div>
                  </div>
                  <div v-if="!hasCont.status" class="input-group mb-3"></div>
                  <input v-if="!hasCont.status"  class="form-control" type="file" ref="contract" accept="application/pdf" />
                  <div class="mt-3  d-flex align-items-end justify-content-end">
                    <button v-if="!hasCont.status" color="success" size="sm" class="btn btn-success" @click="saveFile1('contract')" >Subir</button>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import axios from 'axios';

const body = document.getElementsByTagName("body")[0];

export default {
  name: "profile",
  data() {
    return {
      hasFoto: {
        status: false,
        name: '',
        url: ''
      },
      hasCV: {
        status: false,
        name: '',
        url: ''
      },
      hasCC: {
        status: false,
        name: '',
        url: ''
      },
      hasEPS: {
        status: false,
        name: '',
        url: ''
      },
      hasCP: {
        status: false,
        name: '',
        url: ''
      },
      hasCB: {
        status: false,
        name: '',
        url: ''
      },
      hasRUT: {
        status: false,
        name: '',
        url: ''
      },
      hasTrd:{
        status: false,
        name: '',
        url: ''
      },
      hasCont:{
        status: false,
        name: '',
        url: ''
      },
      showSetup: false,
      showDocuments: true,
      showMenu: false,
      user: {
        username: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        name: JSON.parse(localStorage.getItem("habilidades"))["alegra_contacts.full_name"],
        lasName: JSON.parse(localStorage.getItem("habilidades"))["full_name"],
        address: JSON.parse(localStorage.getItem("userJoget"))["address"],
        city: JSON.parse(localStorage.getItem("userJoget"))["city"],
        department: JSON.parse(localStorage.getItem("userJoget"))["department"],
        nit: JSON.parse(localStorage.getItem("userJoget"))["nit"],
        phone: JSON.parse(localStorage.getItem("userJoget"))["phone"],
      },
      userJoget: JSON.parse(localStorage.getItem('userJoget')),
      URL: localStorage.getItem("URL"),
    };
  },
  components: { ArgonInput, ArgonButton },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.habilidades()
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
  methods:{
    async saveFile1 (reference) {
      console.log('el archivo', reference)
      const vm = this
      try {
        const { files } = this.$refs[reference]
        this.loading = true
        console.log('archivos', files)
        const file = files[0]
        if (file) {
          // const isFileType = file.type === 'application/pdf'
          // const isFileType2 = file.type === 'image/png'
          if (file.size < 1000000) {
            const form = new FormData()
            form.append('id', this.userJoget['id_alegra'])
            form.append(reference, file, file.name)
            console.log('informacion del form', form)
            await axios.post(process.env.VUE_APP_API_BASE_URL+'/jw/api/form/0_0_1_Documentos/updateWithFiles', form, {
              responseType: 'json',
              headers: {
                accept: 'application/json',
                api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
                api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
              }
            })
              .then(function (res) {
                console.log('respuesta', res)
                if (res.status === 200) {
                  // core.showSnackbar('true', 'guardado exitoso')
                  // this.$toast.success(`Proceso Exitoso`);
                  vm.scheduleModal = false
                  if (reference === 'hoja_vida') {
                    vm.hasCV.status = true
                    vm.hasCV.name = file.name
                  }
                  if (reference === 'cedula') {
                    vm.hasCC.status = true
                    vm.hasCC.name = file.name
                  }
                  if (reference === 'eps') {
                    vm.hasEPS.status = true
                    vm.hasEPS.name = file.name
                  }
                  if (reference === 'pension') {
                    vm.hasCP.status = true
                    vm.hasCP.name = file.name
                  }
                  if (reference === 'bancario') {
                    vm.hasCB.status = true
                    vm.hasCB.name = file.name
                  }
                  if (reference === 'rut') {
                    vm.hasRUT.status = true
                    vm.hasRUT.name = file.name
                  }
                  if (reference === 'tratamiento_datos') {
                    vm.hasTrd.status = true
                    vm.hasTrd.name = file.name
                  }
                  if (reference === 'contract') {
                    vm.hasCont.status = true
                    vm.hasCont.name = file.name
                  }
                  if (reference === 'foto') {
                    console.log('foto', reference)
                    axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/0_0_1_documentos?id=' + JSON.parse(localStorage.getItem('userJoget'))['id_alegra'], {
                      responseType: 'json',
                      headers: {
                        accept: 'application/json',
                        api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
                        api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
                      }
                    })
                      .then(function (res) {
                        if (res.status === 200) {
                          console.log('respuestas', res.data)
                          localStorage.setItem('documentos', JSON.stringify(res.data.data[0]))
                          var arrayDeCadenas = res.data.data[0].foto.split('"')
                          var URL = process.env.VUE_APP_API_BASE_URL + arrayDeCadenas[1]
                          localStorage.setItem('URL', URL)
                          vm.URL = URL
                          console.log('url separada', URL)
                          // vm.userJoget.put()
                        }
                      })
                      .catch(function (err) {
                        console.log('error de consulta', err)
                      })
                    // localStorage.setItem('URL', URL)
                    // vm.hasRUT.status = true
                    // vm.hasRUT.name = file.name
                  }
                  // vm.$forceUpdate()
                  vm.obtenerDocument ()
                }
              })
              .catch(function (err) {
                console.log('error de almacenaje', err)
              })
            // console.log('archivo disponible en ', url)
            // this.downloadUrl = url
            // this.poder.downloadUrl = url
          } else {
            this.$toast.error(`El archivo excede el peso permitido. Por favor, selecciona un archivo PDF inferior a 1MB. Si requieres comprimir un documento, puedes hacerlo desde https://www.adobe.com/la/acrobat/online/compress-pdf.html`);
            //vm.makeToast('danger', 'El archivo excede el peso permitido. Por favor, selecciona un archivo PDF inferior a 1MB. Si requieres comprimir un documento, puedes hacerlo desde https://www.adobe.com/la/acrobat/online/compress-pdf.html')
          }
        } else {
          this.$toast.error('danger', 'Archivo invalido. Por favor, selecciona un archivo PDF inferior a 1MB.')
        }
      } catch (error) {
        this.$toast.error('danger', 'Archivo invalido. Por favor, selecciona un archivo PDF inferior a 1MB.')
        //console.error(error)
      }
      this.loading = false
    },
    async habilidades () {
      var userJoget = JSON.parse(localStorage.getItem('userJoget'))
      // var habilidades = JSON.parse(localStorage.getItem('habilidades'))
      this.user.phonePrimary = userJoget['phone']
      // this.user.address.city = userJoget['alegra_contacts.city']
      // this.user.address.department = userJoget['alegra_contacts.department']
      // this.user.address.address = userJoget['alegra_contacts.address']
      // this.userJoget.name = habilidades['alegra_contacts.full_name']
      var existing = localStorage.getItem('habilidades')
      existing = existing ? JSON.parse(existing) : {}
      this.user.staff = {
        acta: existing.actas,
        conductor: existing.conductor,
        conexion: existing.conexion,
        registro: existing.registro_asistencia,
        sonido: existing.operario_sonido,
        soporte: existing.soporte,
        virtual: existing.asamblea_virtual
      }
      var documentos1 = JSON.parse(localStorage.getItem('documentos'))
      if (documentos1.foto !== '') {
        this.hasFoto.status = true
        this.hasFoto.name = documentos1.foto
      }
      if (documentos1.hoja_vida !== '') {
        this.hasCV.status = true
        this.hasCV.name = documentos1.hoja_vida
      }
      if (documentos1.cedula !== '') {
        this.hasCC.status = true
        this.hasCC.name = documentos1.cedula
      }
      if (documentos1.eps !== '') {
        this.hasEPS.status = true
        this.hasEPS.name = documentos1.eps
      }
      if (documentos1.pension !== '') {
        this.hasCP.status = true
        this.hasCP.name = documentos1.pension
      }
      if (documentos1.bancario !== '') {
        this.hasCB.status = true
        this.hasCB.name = documentos1.bancario
      }
      if (documentos1.rut !== '') {
        this.hasRUT.status = true
        this.hasRUT.name = documentos1.rut
      }
      if (documentos1.tratamiento_datos !== '') {
        this.hasTrd.status = true
        this.hasTrd.name = documentos1.tratamiento_datos
      }
      if (documentos1.contract !== '') {
        this.hasCont.status = true
        this.hasCont.name = documentos1.contract
      }
    },
    resetDocument (type, kind) {
        if (kind === 'edit') {
          this[`has${type}`] = {
            status: false,
            name: '',
            url: ''
          }
        } else {
          this[`has${type}`] = this.user[`has${type}`]
        }
      },
      obtenerDocument () {
        const vm = this
        axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/0_0_1_documentos?id=' + JSON.parse(localStorage.getItem('userJoget'))['id_alegra'], {
          responseType: 'json',
          headers: {
            accept: 'application/json',
            api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
            api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
          }
        })
          .then(function (res) {
            if (res.status === 200) {
              console.log('respuestas', res.data)
              localStorage.setItem('documentos', JSON.stringify(res.data.data[0]))
              vm.habilidades()
            }
          })
          .catch(function (err) {
            console.log('error de consulta', err)
          })
        // localStorage.setItem('URL', URL)
        // vm.hasRUT.status = true
        // vm.hasRUT.name = file.name
      }
  }
};
</script>

/*
Ejmplos de uso del toast
this.$toast.show(`Hey! I'm here`);
this.$toast.success(`Hey! I'm here`);
this.$toast.error(`Hey! I'm here`);
this.$toast.warning(`Hey! I'm here`);
this.$toast.info(`Hey! I'm here`);
*/
