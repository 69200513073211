<template>
  <ArgonAlert v-if="showAlert" @close="resetShowAlert"
  color="info" icon="ni ni-like-2 ni-lg" time="4000" dismissible="true">
    <strong>Confirmado!</strong> Todos los archivos subidos con exito!
  </ArgonAlert>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-2">
            <h6>Informes</h6>
          </div>
          <!-- Modal -->
          <div class="modal fade" id="modalUpDoc" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalUpDocLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="modalUpDocLabel">Guardar Informes</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container file-item col-10" v-for="(sf, index) in files.files" :key="index" >
                      <div class="row  mb-2" v-if="sf.idCot == files.openedRow.idCot">
                          <div class="col-8" >
                            <p >{{sf.name}}</p>
                          </div>
                          <div class="col-4 justify-content-end">
                            <ArgonButton v-if="!this.progressBar[index]" color="warning" type="button" size="xs" @click="deleteSelectedFiles(sf)" class="btn btn-primary">Borrar</ArgonButton>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12 loader" :style="{ width: this.progressBar[index]}" v-if="this.progressBar[index]">
                            {{this.progressBar[index]}}
                        </div>
                      </div>
                    </div>
                    <div class="dropzone mt-4" v-if="!this.files.files.length" v-bind="getRootProps()">
                      <div class="bordercap" :class="{ isDragActive,}">
                        <input v-bind="getInputProps()" />
                        <p v-if="isDragActive">Drop the files here ...</p>
                        <p v-else>Drag and Drop todos los documentos de la Asamblea</p>
                        <ArgonButton v-if="!isDragActive" color="success" size="xs"  >Abrir</ArgonButton> 
                      </div>
                    </div>
                    <div class="accordion mt-3" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Archivos subidos anteriormente
                          </button>
                          
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapsed" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div class="table-responsive p-0">
                              <table v-if="(uploadeDocs.reports.length > 0)" class="table align-items-center mb-0">
                                <thead>
                                  <th>Nombre</th>
                                </thead>
                                <tbody>
                                  <tr v-for="(rp,index) in uploadeDocs.reports" :key="index">
                                    <td>
                                      {{rp}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p v-else>No hay elementos subidos aún.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="modal-footer">
                  <ArgonButton color="warning" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</ArgonButton>
                  <ArgonButton @click="saveFiles(files.files, files.openedRow)" color="success" type="button" class="btn btn-primary">Subir Archivos</ArgonButton>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th v-for="hdr in headers" :key="hdr + index" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{hdr}}</th>
                  </tr>
                </thead>
                <tbody v-if="tableData.length > 0">
                  <tr v-for="(tr, index) in tableData" :key="index">
                    <td class="w-1">
                      <div class="d-flex justify-content-center px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{index+1}}</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-2 py-0">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{tr.nombrePhEmpresa}}</h6>
                          <small class="m-0 text-xs">{{convertLongDate(tr.firstDate)}}</small>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-4 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <span v-if="(tr.docStatus === 'true')" class="badge badge-sm bg-gradient-success">Aprobados</span>
                          <span v-else class="badge badge-sm bg-gradient-warning">No Aprobados</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-3 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <span v-if="(tr.docRecieved === 'true')" class="badge badge-sm bg-gradient-success">Recibido</span>
                          <span v-else class="badge badge-sm bg-gradient-warning">Por Subir</span>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="d-flex px-5 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <a @click="getSelectedRow(tr);getUploadedFiles(tr.idCot)"
                            href="javascript:;"
                            class="text-secondary font-weight-bold text-xs"
                            data-toggle="tooltip"
                            data-original-title="Edit user"
                            data-bs-target="#modalUpDoc"
                            data-bs-toggle="modal"
                          >Subir Archivos</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td>
                      No Tienes Asambleas Asignadas
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ArgonButton from '../components/ArgonButton.vue';
import { reactive } from 'vue'
import { useDropzone } from "vue3-dropzone";
import ArgonAlert  from "../components/ArgonAlert.vue";



export default {
  name: "informes",
  data(){
    return{
      headers: ['Nº','Asamblea', 'Estado Documentos', 'Documentos', 'Subir Documentos'],
      tableData: [{}],
      progressBar: {},
      uploadeDocs:{"reports":[]},
      showAlert: false,
    }
  },
  async beforeMount(){
    await this.getAsemblies()
  },
  setup(){
    const files = reactive({
      files: [],
      openedRow: {},
    })
    function onDrop(acceptFiles, rejectReasons) {
      
      acceptFiles.forEach(index =>{
        index.idCot = files.openedRow.idCot
      })
      files.files = files.files.concat(acceptFiles)
      console.log(files.files)
      console.log("reject",rejectReasons)
    }
    
    const options = reactive({
      multiple: true,
      onDrop,
      accept:".xls,.xlsx,.csv,.pdf,image/*,.rar,.zip",
    })
    const { getRootProps, getInputProps, ...rest } = useDropzone(options)
    return {
      getRootProps,
      getInputProps,
      ...rest,
      files,
      
    }
  },
  methods:{
    resetShowAlert(varAlert){
      this.showAlert = varAlert
    },
    async getAsemblies(){
      const vm = this
      await axios.get(process.env.VUE_APP_API_BASE_URL+'/jw/api/list/prestadores_documentos?temporal.id_alegra=' + JSON.parse(localStorage.getItem('userJoget'))['id_alegra']+'&temporal.c_full_name='+JSON.parse(localStorage.getItem('habilidades'))['alegra_contacts.full_name'], {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          if (res.status === 200 && res.data.data.length > 0) {
            res.data.data.forEach(vm.mapJsonAssemblies)
            vm.tableData.sort((a,b)=>b.firstDate-a.firstDate)
            console.log(res.data.data)
          }
        })
        .catch(function (err) {
          console.log('error consultando las asambleas', err)
        })
    },
    async mapJsonAssemblies(data, index){
        this.tableData[index] = {'id':data.id,
        'docRecieved': data['reports.doc_received'],
        'idAlegra': data['temporal.id_alegra'],
        'idCot': data.id_cot,
        'nombrePhEmpresa': data['sol_cot_client.nombre_ph_empresa'],
        'docStatus': data.doc_status,
        'firstDate':this.parseDate(data['sol_cot_service.fecha_primera_convocatoria']),
      }
    },
    async saveFiles(files, openedRow){
      let vm = this
       // pass data as a form
       const formData = new FormData();
      for (var x = 0; x < files.length; x++) {
        // append files as array to the form, feel free to change the array name
        if(!(files[x].idCot == openedRow.idCot)){
          console.log('No Hay Datos') 
          return 
        }
        formData.append('id', openedRow.idCot)
        formData.append("reports", files[x], files[x].name)
        console.log(formData, files[x], openedRow.idCot)
        await axios.post(process.env.VUE_APP_API_BASE_URL + '/jw/api/form/reports/updateWithFiles', formData, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c',
          "Content-Type": 'multipart/form-data',
        },
        params: {
            'appendFile': 'true',
        },
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent)
          const total = progressEvent.total
          const totalLength = progressEvent.event.lengthComputable ? total : null
          if(totalLength !== null){
            this.progressBar[x] =  Math.round((progressEvent.loaded * 100) / total) + '%'
            console.log(this.progressBar)
          }
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            console.log('subido bien', res.data)
            vm.updateUploadFiles(files[x].name)
          }
        })
        .catch(function (err) {
          console.log('No se pudo subir', err)
        })
      }
      //si todo sube bien
      vm.showAlert=true
      try {
        await this.updateDocRecieved(openedRow.idCot)
      } catch (error) {
        console.log("no se pudo actualizar doc_recieved", error)
      }
    },
    async getSelectedRow(json){
      this.files.openedRow = json
      console.log(json)

    },
    async getUploadedFiles(id_cot){
      let vm = this
      vm.uploadeDocs.reports = []
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/jw/api/form/reports/'+id_cot, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c',
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            console.log('data geted', res.data)
            if(res.data.reports.length > 0){
            let Reports = res.data.reports.split(";")
            vm.updateUploadFiles(Reports)
          }
          }
        })
        .catch(function (err) {
          console.log('error obteniendo los archivos ya subidos', err)
        })
    },
    deleteSelectedFiles(data){
      this.files.files.forEach((obj,index) =>{
        console.log(obj,index)
        if(obj.idCot === data.idCot && obj.name === data.name){
          this.files.files.splice(index,1)
          console.log(this.files.files)
        }
      })
    },
    updateUploadFiles(fileName){
      console.log("saber si es un array",Array.isArray(fileName))
      if(!Array.isArray(fileName)){
        console.log("entro donde no es")
      this.uploadeDocs.reports.push(fileName)
      }
      if(Array.isArray(fileName)){
        this.uploadeDocs.reports = []
        console.log("entro a lo que es array")
        fileName.forEach(element =>{
          console.log(element)
          this.uploadeDocs.reports.push(element)
          console.log(this.uploadeDocs)
        })
      }
    },
    async updateDocRecieved(id_cot){
      //let vm = this
      await axios.put(process.env.VUE_APP_API_BASE_URL + '/jw/api/form/reports/',{'id':id_cot,'doc_received':'true'}, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c',
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            console.log('doc_recieved correctamente', res.data)
          }
        })
        .catch(function (err) {
          console.log('error al intentar actualizar doc_recieved', err)
        })
    },
     parseDate(str) {
      // Suponiendo que el formato es 'DD-MM-YYYY HH:MM PM/AM'
      let [day, month, yearTime] = str.split('-');
      let [year, time] = yearTime.split(' ');
      let [hours, minutes] = time.substring(0, 5).split(':');

      if (time.includes('PM') && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
      } else if (time.includes('AM') && hours === '12') {
        hours = '00';
      }

      // Los meses en JavaScript son de 0-11 en lugar de 1-12
      month = parseInt(month, 10) - 1;
      return new Date(year, month, day, hours, minutes);
    },
    convertLongDate(date) {
      // Opciones para formatear la fecha
      const opciones = { year: 'numeric', month: 'long', day: 'numeric' };

      // Formatear la fecha al estilo largo
      return new Intl.DateTimeFormat('es-ES', opciones).format(date);
    }
  },
  components:{
    ArgonButton,
    ArgonAlert,
  }
};
</script>

<style lang="scss" scoped>
.dropzone,
.files {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
}

.bordercap {
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: all 0.3s ease;
  background: #fff;
  

  &.isDragActive {
    border: 2px dashed #ffb300;
    background: rgb(255 167 18 / 20%);
  }
}

.file-item {
  border-radius: 8px;
  display: flex;
  background: rgb(255 167 18 / 20%);
  padding: 16px 20px 10px 20px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  align-content: center;
  margin-top: 7px;

  &:first-child {
    margin-top: 0;
  }

  .delete-file {
    background: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }
}
.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0%;
  background-color:#2dce89;
  border-radius: 8px;
  height: 15px;
}
</style>