<template>
   <ArgonAlert v-if="showAlert" @close="resetShowAlert" :color="alertType" icon="ni ni-like-2 ni-lg" :time=4000
      :dismissible=true>
      {{ alertMsg }}
   </ArgonAlert>
   <div class="py-4 container-fluid">
      <div class="row">
         <div class="col-lg-12">
            <div class="row">
               <div class="col-lg-8 mb-4">
                  <!-- Here initates the modal to select the services in a Account Statement-->
                  <div class="modal fade" id="selectServices" data-bs-backdrop="static" tabindex="-1" aria-labelledby="selectServicesLabel" aria-hidden="true">
                     <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                           <div class="modal-header">
                           <h5 class="modal-title" id="selectServiceslLabel">Selecciona los servicios disponibles para cobrar</h5>
                           <button type="button" class="btn-close" data-bs-target="#AccStatementModal" data-bs-toggle="modal" data-bs-dismiss="modal" aria-label="Close"></button>
                           </div>
                           <div class="modal-body">
                              <div class="row">
                                 <div class="col-lg-12">
                                    <PickList v-model="services" :showSourceControls="false"
                                       :showTargetControls="false" :stripedRows="true" dataKey="cotNumber">
                                       <template #sourceheader>
                                          Servicios Disponibles
                                          <div class="table-responsive p-0">
                                             <table class="table align-items-center mb-0">
                                                <thead>
                                                   <th>Asamblea</th>
                                                   <th>Fecha</th>
                                                </thead>
                                             </table>
                                          </div>
                                       </template>
                                       <template #targetheader>
                                          Servicios Seleccionados
                                          <div class="table-responsive p-0">
                                             <table class="table align-items-center mb-0">
                                                <thead>
                                                   <th>Asamblea</th>
                                                   <th>Fecha</th>
                                                </thead>
                                             </table>
                                          </div>
                                       </template>
                                       <template #item="slotProps">
                                          <div class="card-body">
                                             <div class="table-responsive p-0">
                                                <table class="table align-items-center mb-0">
                                                   <tbody>
                                                      <tr>
                                                         <td class="text-wrap">
                                                            {{slotProps.item.serviceName}}
                                                         </td>
                                                         <td>
                                                            {{slotProps.item.serviceDate}}
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </template>
                                    </PickList>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer">
                           <ArgonButton color="warning" class="btn btn-primary" data-bs-target="#AccStatementModal" data-bs-toggle="modal" data-bs-dismiss="modal">Atras</ArgonButton>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- Here ends the modal to select the services in a Account Statement-->
                  <!-- Here initates the modal to create, edit and view a new Account Statement-->
                  <div class="modal fade" id="AccStatementModal" tabindex="-1" aria-labelledby="AccStatementModalLabel"
                     aria-hidden="true">
                     <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                           <div class="modal-header">
                              <h5 class="modal-title" id="AccStatementModalLabel">
                                 Cuenta de Cobro #{{ accountStatementDataModel.id }}
                              </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal"
                                 aria-label="Close"></button>
                           </div>
                           <div class="modal-body">
                              <div class="container-fluid">
                                 <div class="row">
                                    <div class="col-lg-12">
                                       <form action="">
                                          <div class="row">
                                             <div class="d-flex flex-column align-items-center align-content-center">
                                                <h4>Clase Alpha SAS</h4>
                                                <h4>NIT 901153061 - 4</h4>
                                                <h4>Trv 34 A Sur # 32B - 29</h4>
                                                <h4>Debe a:</h4>
                                             </div>
                                          </div>
                                          <div class="row justify-content-center">
                                             <div class="col-lg-6">
                                                <label for="" class="form-control-label">Prestador
                                                </label>
                                                <ArgonInput name="" v-model="accountStatementDataModel.name"
                                                   type="String" class="form-control-default" :isRequired=true
                                                   :disabled="true" :valid="true"></ArgonInput>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="col-lg-12">
                                                <h4 class="mt-3">Los Siguientes Servicios:</h4>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="col-lg-12">
                                                <div class="table-responsive p-0">
                                                   <table class="table align-items-center mb-0">
                                                      <thead>
                                                         <th v-for="(th, index) in serviceThead" :key="index"
                                                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-9">
                                                            <div class="d-flex justify-content-center">
                                                               {{ th }}
                                                            </div>
                                                         </th>
                                                      </thead>
                                                      <tbody>
                                                         <tr v-for="(tr, index) in services[1]" :key="index">
                                                            <td>
                                                               <div class="d-flex flex-column align-items-start align-content-start text-wrap ">
                                                                  {{ tr.cotNumber }}
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <div class="d-flex flex-column justify-content-center px-3 py-1 text-wrap">
                                                                  {{ tr.serviceName }}
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <div class="d-flex flex-column justify-content-center px-3 py-1 text-wrap">
                                                                  {{ tr.fullName }}
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <div class="d-flex flex-column justify-content-center px-3 py-1">
                                                                  {{ tr.serviceDate }}
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <div class="d-flex flex-column justify-content-center px-3 py-1">
                                                                  {{ tr.function }}
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <div class="d-flex flex-column justify-content-center px-3 py-1">
                                                                  <ArgonInput type="String" name="serviceValue" v-model="tr.serviceValue" :disabled="!this.editBillSt" v-on:input="sumTotal" 
                                                                  class="form-control-default" :isRequired="true" :valid="true"></ArgonInput>
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <div class="d-flex flex-column justify-content-center px-3 py-1">
                                                                  <ArgonInput type="String" name="extraHr" v-model="tr.extraHr" :disabled="!this.editBillSt" 
                                                                  class="form-control-default" v-on:input="sumTotal" :isRequired="true" :valid="true"></ArgonInput>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                </div>
                                                <div class="row flex-column align-items-center align-content-center" >
                                                         <div class="col-lg-6 mt-3 mb-4 d-flex flex-column align-items-center align-content-center ">
                                                            <ArgonButton v-if="editBillSt" color="success" type="button" data-bs-toggle="modal" 
                                                            data-bs-target="#selectServices" data-bs-dismiss="modal">Agregar Servicios</ArgonButton>
                                                         </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="col-lg-6">
                                                <ArgonBadge color="info mb-2" >Importante!</ArgonBadge>
                                                <p>El valor de la hora extra sera añadido despues de revisada la cuenta de cobro, por lo cual no se vera reflejado en el total hasta ese momento.</p>
                                             </div>
                                             <div class="col-lg-6">
                                                <ArgonBadge v-if="this.createBillSt" color="info mb-2" >Firma</ArgonBadge>
                                                <Vue3Signature ref="signature1" :sigOption="state.option" :w="'300px'" :h="'150px'"
                                                   :disabled="sigDisable" class="example"></Vue3Signature>
                                                   <ArgonButton color="info" size="xs" type="button" @click="clearSig()">Limpiar</ArgonButton>
                                             </div>
                                          </div>
                                          <div class="row">
                                             <div class="col-lg-6">
                                                <label for="total" class="form-control-label">Total Aproximado</label>
                                                <ArgonInput name="total" v-model="accountStatementDataModel.total"
                                                   type="String" class="form-control-default" :isRequired="false"
                                                   :disabled="true" :valid="true"></ArgonInput>
                                             </div>
                                             <div class="col-lg-6">
                                                <label for="paymentMetod" class="form-control-label">Metodo de
                                                   pago</label>
                                                <Dropdown v-model="
                                                   accountStatementDataModel.paymentMethod
                                                " :options="paymentMethods" optionLabel="methodName"
                                                   placeholder="Selecciona un metodo" selectionMessage=" "
                                                   emptySelectionMessage=" " name="paymentMetod" :disabled="!editBillSt" :class="!v$.accountStatementDataModel.paymentMethod.$error ? '':'p-invalid' "/>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer">
                              <ArgonButton color="warning" id="closeCreateEdit"  type="button" class="btn btn-secondary"
                                 data-bs-dismiss="modal" aria-label="Close">Cerrar
                              </ArgonButton>
                              <ArgonButton v-if="editBillSt" color="success" type="button" class="btn btn-primary" @click="createBillSt ? createBillStatement() : editBillStatement()">Guardar
                              </ArgonButton>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- Here ends the modal to create, edit or view a new Account Statement-->
                  <div class="card">
                     <div class="card-header pb-2">
                        <h6>CUENTAS DE COBRO</h6>
                     </div>
                     <div class="card-body">
                        <div v-if="this.tableData.length == 0" class="d-flex justify-content-center">
                           <ArgonBadge color="info">No Tienes Cuentas de cobro</ArgonBadge>
                        </div>
                        <div v-if="this.tableData.length > 0" class="table-responsive p-0">
                           <table class="table align-items-center mb-0">
                              <thead>
                                 <th v-for="(th, index) in theads" :key="index"
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    <div class="d-flex justify-content-between">
                                       {{ th }}
                                    </div>
                                 </th>
                              </thead>
                              <tbody>
                                 <tr v-for="(tr, index) in tableData" :key="index">
                                    <td>
                                       <div class="d-flex flex-column justify-content-center px-3 py-1">
                                          {{ tr.id }}
                                       </div>
                                    </td>
                                    <td>
                                       <div class="d-flex flex-column justify-content-center px-4 py-1">
                                          <ArgonBadge v-if="tr.ccStatus == 'true'" color="success">
                                             Aprobada</ArgonBadge>
                                          <ArgonBadge v-if="tr.ccStatus =='false'" color="info">No Aprobada</ArgonBadge>
                                       </div>
                                    </td>
                                    <td>
                                       <div class="d-flex flex-column justify-content-center px-4 py-1">
                                          <ArgonBadge v-if="tr.payStatus == 'true'" color="success">
                                             Pagada</ArgonBadge>
                                          <ArgonBadge v-else color="info">Por Pagar</ArgonBadge>
                                       </div>
                                    </td>
                                    <td>
                                       <div class="d-flex flex-column justify-content-center px-4 py-1">
                                          {{ tr.dateCreadted }}
                                       </div>
                                    </td>
                                    <td>
                                       <div class="d-flex flex-column justify-content-center px-4 py-1">
                                          {{
                                                "$" + tr.total
                                          }}
                                       </div>
                                    </td>
                                    <td>
                                       <div class="d-flex px-4 py-1">
                                          <div class="d-flex flex-column justify-content-center">
                                             <a v-if="tr.ccStatus=='true'" type="button" class="text-secondary font-weight-bold text-xs"
                                                data-toggle="tooltip" data-original-title="Edit user" @click="getSelectedServices(tr.id, false);mapEditBillingStatement(tr, index)"
                                                data-bs-target="#AccStatementModal" data-bs-toggle="modal">Ver</a>
                                             <a v-else type="button" class="text-secondary font-weight-bold text-xs"
                                             data-toggle="tooltip" data-original-title="Edit user" @click="getSelectedServices(tr.id, true);mapEditBillingStatement(tr, index)"
                                             data-bs-target="#AccStatementModal" data-bs-toggle="modal">Editar</a>
                                          </div>
                                       </div>   
                                    </td>
                                 </tr>
                              </tbody>
                              <tfoot>
                                 
                              </tfoot>
                           </table>
                        </div>
                     </div>
                     <div class="card-footer">
                        <ArgonButton data-bs-target="#AccStatementModal" data-bs-toggle="modal" color="success" size="sm" @click="clearBillStDataModel()">Crear</ArgonButton>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4">
                  <!--Modal to add new payment methond-->
                  <div class="modal fade" tabindex="-1" id="addNewPM">
                     <div class="modal-dialog">
                        <div class="modal-content">
                           <div class="modal-header">
                              <h5 class="modal-title">Metodo de Pago</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal"
                                 aria-label="Close"></button>
                           </div>
                           <div class="modal-body">
                              <div class="container-fluid">
                                 <div class="row">
                                    <div class="col-lg-12">
                                       <div class="row">
                                          <div class="col-lg-6">
                                             <label for="pmName" class="form-control-label">Nombre del
                                                Metodo</label>
                                             <ArgonInput name="pmName" v-model="payMetDataModel.pmName" type="String"
                                                class="form-control-default" isrequired="true" :valid="!v$.payMetDataModel.pmName.$error"></ArgonInput>
                                          </div>
                                          <div class="col-lg-6">
                                             <label for="cc" class="form-control-label">Cedula
                                                Titular</label>
                                             <ArgonInput name="cc" v-model="payMetDataModel.cc" type="String"
                                                class="form-control-default" isrequired="true" :valid="!v$.payMetDataModel.cc.$error"></ArgonInput>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col-lg-6">
                                             <label for="bank" class="form-control-label">Entidad
                                                Bancaria</label>
                                             <AutoComplete name="bank" v-model="payMetDataModel.bank"
                                                :suggestions="filteredBanks" optionLabel="value"
                                                @complete="searchBank($event)" :dropdown="false" selectionMessage=" "
                                                emptySelectionMessage=" " searchMessage=" " emptySearchMessage=" " :class="!v$.payMetDataModel.bank.$error ? '':'p-invalid'">
                                             </AutoComplete>
                                          </div>
                                          <div class="col-lg-6">
                                             <label for="typeAcc" class="form-control-label">Tipo de
                                                cuenta</label>
                                             <Dropdown v-model="payMetDataModel.typeAcc" :options="bankTypes"
                                                optionLabel="label" placeholder="Selecciona un..." selectionMessage=" "
                                                emptySelectionMessage=" " :class="!v$.payMetDataModel.bank.$error ? '':'p-invalid'"/>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col-lg-12">
                                             <label for="accNumber" class="form-control-label">Numero de
                                                cuenta</label>
                                             <ArgonInput name="accNumber" v-model="payMetDataModel.accNumber"
                                                type="String" class="form-control-default" :isrequired="true" :valid="!v$.payMetDataModel.accNumber.$error">
                                             </ArgonInput>
                                             <span class="p-error" v-if="v$.payMetDataModel.accNumber.$error">Completa todos los campos!</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer">
                              <ArgonButton color="warning" id="closeBtn" type="button" class="btn btn-secondary"
                                 data-bs-dismiss="modal" aria-label="Close">Cerrar
                              </ArgonButton>
                              <ArgonButton v-if="!editPaymentMt" color="success" type="button" class="btn btn-primary"
                                 @click="addPaymentMethod()">Agregar Metodo
                              </ArgonButton>
                              <ArgonButton v-else color="success" type="button" class="btn btn-primary"
                                 @click="editPaymentMethod();">Editar Metodo</ArgonButton>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="card pb-4">
                     <div class="card-header pb-0 p-3">
                        <div class="row">
                           <div class="col-6 d-flex align-items-center">
                              <h6 class="mb-0">Metodo de pago</h6>
                           </div>
                           <div class="col-6 text-end">
                              <argon-button v-if="paymentMethods.length < 3" color="success" size="sm" variant="outline" data-bs-toggle="modal"
                                 data-bs-target="#addNewPM" @click="clearPayMetDataModel()">Agregar</argon-button>
                           </div>
                        </div>
                     </div>
                     <div class="card-body p-3 pb-0 mb-0">
                        <ul class="list-group">
                           <li v-for="(pm, index) in paymentMethods" :key="index"
                              class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                              <div class="d-flex flex-column">
                                 <h6 class="mb-1 text-dark font-weight-bold text-sm">
                                    {{ pm.methodName }}
                                 </h6>
                                 <span class="text-xs">Cuenta#: {{ pm.accNumber }}</span>
                                 <span class="text-xs">Tipo: {{ pm.accType }}</span>
                              </div>
                              <div class="d-flex align-items-center text-sm">
                                 <button @click="this.mapEditPaymentMethod(pm, index)"
                                    class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" data-bs-toggle="modal"
                                    data-bs-target="#addNewPM">
                                    <i class="text-lg me-1" aria-hidden="true"></i> Editar
                                 </button>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import ArgonButton from "../components/ArgonButton.vue";
import ArgonBadge from "../components/ArgonBadge.vue";
import axios from "axios";
import ArgonInput from "../components/ArgonInput.vue";
import AutoComplete from "primevue/autocomplete";
import Dropdown from "primevue/dropdown";
import ArgonAlert from "../components/ArgonAlert.vue";
import PickList from "primevue/picklist";
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Vue3Signature from "vue3-signature"
import { ref } from "vue";

export default {
   name: "billingstatement",
   data() {
      return {
         v$:useVuelidate(),
         theads: ["CC-#", "Estado", "Pago", "Fecha", "Total", "Ver"],
         serviceThead:['# Cotizacion','Nombre Ph/Empresa','Prestador','Fecha','Rol','Valor Servicio','Horas Extras'],
         tableData: [],
         paymentMethods: [{}],
         banks: [{}],
         services: [[], []],
         payMetDataModel: {
            pmName: "",
            accNumber: "",
            typeAcc: "",
            bank: "",
            cc: "",
         },
         accountStatementDataModel: {
            id: null,
            name: JSON.parse(localStorage.getItem("habilidades"))["alegra_contacts.full_name"],
            idAlegra: null,
            ccStatus: null,
            dateCreadted: null,
            total: null,
            observations: null,
            paymentMethod: null,
            direccion: null,
            hrValue: 0,
            adjust: 0,
            signatureName: '',
         },
         filteredBanks: null, //this is a temporaly var to recieve the filter databa by the bank input
         bankTypes: [
            { value: "ahorros", label: "Ahorros" },
            { value: "corriente", label: "Corriente" },
         ],
         editPaymentMt: false, //variable used to mark out when create or update a payment method
         editBillSt: false,
         createBillSt: false,
         modalAddNewPM: null,
         editIndexPM: null, //index to know who Payment Method im editing
         editIndexBS: null, //index to know who Billing Statement im editing
         showAlert: false,
         alertMsg: null,
         alertType: null,
         loader: '',
         state: {
            count: 0,
            option: {
               penColor: "rgb(0, 0, 0)",
               backgroundColor: "rgb(240,240,240)"
            }
         },
         sigDisable: false,
         
      };
   },
   setup(){
      const signature1 = ref(null)
      return{
         signature1,
      }
   },
   validations(){
      return{
         payMetDataModel: {
            pmName: { required },
            accNumber: { required },
            typeAcc: { required },
            bank: { required },
            cc: { required },
         },
         accountStatementDataModel: {
            paymentMethod: { required },
         }, 
      }
   },
   beforeMount() {
      this.getBillingStatements();
      this.getPaymentMethods();
      this.getBanksName();
      this.getListOfServices();
   },
   mounted() {
      this.closeModalAddNewPM = document.getElementById("closeBtn")
      this.closeModalAddNewbs = document.getElementById("closeCreateEdit")
   },
   methods: {
      loaderfunction(state){
        this.loader = this.$loading.show({
                    // Optional parameters
                    active: state,
                    canCancel: false,
                    color: '#006188',
                    transition: 'fade',
                    width: 115,
                    height: 115,
                    loader: 'dots',
                    backgroundColor: '#D1D1D1',
                    lockScroll: true,
                    isFullPage: true,
                    
                });
      },
      resetShowAlert(varAlert) {
         this.showAlert = varAlert;
      },
      sumTotal(){
         if(this.services[1].length > 0){
            let tempSum = 0
            let tempHrExtra = 0
            this.services[1].forEach(tmpData => {
               tempSum += Number(tmpData.serviceValue)
               tempHrExtra += Number(tmpData.extraHr)
            })
            console.log(tempSum)
            if(this.editIndexBS != null){
               this.accountStatementDataModel.total = tempSum + Number(this.tableData[this.editIndexBS].adjust) + (Number(this.tableData[this.editIndexBS].extraHrValue)*tempHrExtra)
               return 
            }
            this.accountStatementDataModel.total = tempSum
         }
         return null
      },
      showAlertMethod(msg, type) {
         this.alertType = type;
         this.alertMsg = msg;
         this.showAlert = true;
      },
      mapJsonBS(data, index) {
         let thisDate = data.dateCreated.split(" ");
         this.tableData[index] = {
            id: data.id,
            idAlegra: data.id_alegra,
            ccStatus: data["cc_status"],
            dateCreadted: thisDate[0],
            total: data.total,
            observations: data.observations,
            paymentMethod: data.payment_method,
            direccion: data.direccion,
            extraHrValue: data.extra_hour_value,
            adjust: data.adjust,
            payStatus: data.pay_status,
            signatureName: data.signature
         }
      },
      mapJsonAfterEditBS(data , index){
         this.tableData[index].total = String(data.total)
         this.tableData[index].paymentMethod = data.list_payment_method[0].id
         
      },
      mapJsonPM(data, index) {
         this.paymentMethods[index] = {
            id: data.id,
            bank: data.bank_name,
            methodName: data.method_name,
            accType: data.account_type,
            accNumber: data.account_number,
            cc: data.cc,
         };
      },
      mapJsonServicesGet(data, index) {
         this.services[0][index] = {
            fullName: data.c_c_full_name,
            serviceDate: data.c_fecha,
            id_alegra: data.c_id_alegra,
            cotNumber: data.c_cot_number,
            serviceName: data.c_nombre_ph_empresa,
            function: data.c_funcion,
            serviceValue: null,
            extraHr: null,
         };
      },
      mapJsonServicesEdit(data, index) {
         this.services[1][index] = {
            fullName: data.c_c_full_name,
            serviceDate: data.c_fecha,
            id_alegra: data.c_id_alegra,
            cotNumber: data.c_cot_number,
            serviceName: data.c_nombre_ph_empresa,
            function: data.c_funcion,
            serviceValue: data.service_value,
            extraHr: data.extra_hours,
         };
      },
      mapEditBillingStatement(data, index){
         this.editIndexBS = index;
         this.createBillSt = false
         this.disableSig(true)
         this.accountStatementDataModel = {
            id: data.id,
            name: JSON.parse(localStorage.getItem("habilidades"))["alegra_contacts.full_name"],
            idAlegra: data.idAlegra,
            ccStatus: data.ccStatus,
            dateCreadted: data.dateCreadted,
            total: data.total,
            observations: data.observations,
            paymentMethod: (this.paymentMethods.filter(pm => pm.id == data.paymentMethod))[0],
            direccion: data.direccion,
            hrValue: data.extraHrValue,
            adjust: data.adjust,
            signatureName: data.signatureName,
         }
         this.clearSig()
         this.signature1.fromDataURL(process.env.VUE_APP_API_BASE_URL+"/jw/web/client/app/acomercial2022/2/form/download/cuenta_cobro/"+this.accountStatementDataModel.id+"/"+this.accountStatementDataModel.signatureName+".")
      },
      mapJsonBanks(data, index) {
         this.banks[index] = { value: data.value };
      },
      mapEditPaymentMethod(data, index) {
         this.editIndexPM = index;
         this.editPaymentMt = true;
         this.payMetDataModel.id = data.id;
         this.payMetDataModel.pmName = data.methodName;
         this.payMetDataModel.accNumber = data.accNumber;
         this.payMetDataModel.bank = { value: data.bank };
         this.payMetDataModel.cc = data.cc;
         if (data.accType == this.bankTypes[0].value) {
            this.payMetDataModel.typeAcc = this.bankTypes[0];
            return;
         }
         this.payMetDataModel.typeAcc = this.bankTypes[1];
      },
      async getPaymentMethods() {
         let vm = this;
         await axios
            .get(
               process.env.VUE_APP_API_BASE_URL +
               "/jw/api/list/payment_methods_api?id_alegra=" +
               JSON.parse(localStorage.getItem("userJoget"))["id_alegra"]+"&alegra_contacts.nit="+JSON.parse(localStorage.getItem("userJoget"))["nit"],
               {
                  headers: {
                     accept: "application/json",
                     api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                     api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                  },
               }
            )
            .then(function (res) {
               if (res.status == 200) {
                  console.log(res.data);
                  res.data.data.forEach(vm.mapJsonPM);
               }
            })
            .catch(function (error) {
               console.log(error);
            });
      },
      async addPaymentMethod() {
         let vm = this;
         vm.v$.$validate()
         if(!vm.v$.payMetDataModel.$error){
            this.loaderfunction(true)
            let pmData = {
               id_alegra: JSON.parse(localStorage.getItem("userJoget"))["id_alegra"],
               method_name: this.payMetDataModel.pmName,
               bank_name: this.payMetDataModel.bank.value,
               account_number: this.payMetDataModel.accNumber,
               cc: this.payMetDataModel.cc,
               account_type: this.payMetDataModel.typeAcc.value,
            };
            await axios
               .post(
                  process.env.VUE_APP_API_BASE_URL + "/jw/api/form/payment_methods",
                  pmData,
                  {
                     headers: {
                        accept: "application/json",
                        api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                        api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                     },
                  }
               )
               .then(function (res) {
                  if (res.status == 200) {
                     console.log(res.data);
                     pmData.id=res.data.id
                  }
               })
               .catch(function (error) {
                  console.log(error);
                  vm.showAlertMethod(
                     "Se produjo un error, intentalo nuevamente",
                     "warning"
                  );
                  vm.loader.hide()
                  return;
               });
            this.mapJsonPM(pmData, this.paymentMethods.length)
            this.closeModalAddNewPM.click()
            this.showAlertMethod(
               "El Metodo de pago se a agregado correctamente",
               "success"
            );
            vm.loader.hide()
         }
      },
      async editPaymentMethod() {
         let vm = this
         vm.v$.$validate()
         if(!vm.v$.payMetDataModel.$error){
            vm.loaderfunction(true)
            let pmData = {
               id: this.payMetDataModel.id,
               id_alegra: JSON.parse(localStorage.getItem("userJoget"))["id_alegra"],
               method_name: this.payMetDataModel.pmName,
               bank_name: this.payMetDataModel.bank.value,
               account_number: this.payMetDataModel.accNumber,
               cc: this.payMetDataModel.cc,
               account_type: this.payMetDataModel.typeAcc.value,
            };
            await axios
               .put(
                  process.env.VUE_APP_API_BASE_URL + "/jw/api/form/payment_methods",
                  pmData,
                  {
                     headers: {
                        accept: "application/json",
                        api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                        api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                     },
                  }
               )
               .then(function (res) {
                  if (res.status == 200) {
                     console.log(res.data);
                     vm.loader.hide()
                  }
               })
               .catch(function (error) {
                  console.log(error);
                  vm.showAlertMethod(
                     "Ocurrio un error intente nuevamente",
                     "warning"
                  );
                  vm.loader.hide()
                  return;
               });
            //this.editPaymentMt = false;
            this.closeModalAddNewPM.click()
            this.mapJsonPM(pmData, this.editIndexPM)
            this.showAlertMethod(
               "El Metodo de pago se modifico correctamente",
               "success"
            )
         }
      },
      async getBanksName() {
         let vm = this;
         await axios
            .get(
               process.env.VUE_APP_API_BASE_URL +
               "/jw/api/list/dropdowns_backend?grouping=banks",
               {
                  headers: {
                     accept: "application/json",
                     api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                     api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                  },
               }
            )
            .then(function (res) {
               if (res.status == 200) {
                  console.log(res.data);
                  res.data.data.forEach(vm.mapJsonBanks);
               }
            })
            .catch(function (error) {
               console.log(error);
            });
      },
      async getBillingStatements() {
         let vm = this;
         await axios
            .get(
               process.env.VUE_APP_API_BASE_URL +
               "/jw/api/list/list_cuenta_cobro_api?id_alegra=" +
               JSON.parse(localStorage.getItem("userJoget"))["id_alegra"]+"&documento="+JSON.parse(localStorage.getItem("userJoget"))["nit"],
               {
                  headers: {
                     accept: "application/json",
                     api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                     api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                  },
               }
            )
            .then(function (res) {
               if (res.status == 200) {
                  console.log(res.data);
                  res.data.data.forEach(vm.mapJsonBS);
               }
            })
            .catch(function (error) {
               console.log(error);
            });
      },
      async getListOfServices() {
         let vm = this;
         await axios
            .get(
               process.env.VUE_APP_API_BASE_URL +
               "/jw/api/list/service_paper_api?c_id_alegra=" +
               JSON.parse(localStorage.getItem("userJoget"))["id_alegra"]+"&c_c_full_name="+JSON.parse(localStorage.getItem("habilidades"))["alegra_contacts.full_name"],
               {
                  headers: {
                     accept: "application/json",
                     api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                     api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                  },
               }
            )
            .then(function (res) {
               if (res.status == 200) {
                  console.log(res.data);
                  res.data.data.forEach(vm.mapJsonServicesGet);
               }
            })
            .catch(function (error) {
               console.log(error);
            });
      },
      async getSelectedServices(cotNumber, edit){
         let vm = this;
         this.editBillSt = edit
         await axios
            .get(
               process.env.VUE_APP_API_BASE_URL +
               "/jw/api/form/cuenta_cobro/"+cotNumber+"?includeSubformData=true&includeReferenceElements=false&flattenData=true",
               {
                  headers: {
                     accept: "application/json",
                     api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                     api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                  },
               }
            )
            .then(function (res) {
               if (res.status == 200) {
                  console.log(res.data);
                  res.data.services.forEach(vm.mapJsonServicesEdit)
               }
            })
            .catch(function (error) {
               console.log(error)
            });
      },
      async editBillStatement(){
         let vm = this
         this.sumTotal()
         vm.v$.$validate()
         if(!vm.accountStatementDataModel.paymentMethod.$error){
            vm.loaderfunction(true)
            let baData = 
            {
               "id": this.accountStatementDataModel.id,
               "id_alegra": this.accountStatementDataModel.idAlegra,
               "prestador_servicio": this.accountStatementDataModel.name,
               "services": [],
               "observations": this.accountStatementDataModel.observations,
               "total": this.accountStatementDataModel.total,
               "list_payment_method": [
                  {
                     "id": this.accountStatementDataModel.paymentMethod.id
                  }
               ]
            }
            this.services[1].forEach(sv => {
               baData.services.push({
                  "c_cot_number": sv.cotNumber,
                  "c_nombre_ph_empresa": sv.serviceName,
                  "c_c_full_name": sv.fullName,
                  "c_fecha": sv.serviceDate,
                  "c_funcion": sv.function,
                  "c_id_alegra": sv.id_alegra,
                  "service_value": sv.serviceValue,
                  "extra_hours": sv.extraHr
               })
            })
            await axios
               .put(
                  process.env.VUE_APP_API_BASE_URL + "/jw/api/form/cuenta_cobro",
                  baData,
                  {
                     headers: {
                        accept: "application/json",
                        api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                        api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                     },
                  }
               )
               .then(function (res) {
                  if (res.status == 200) {
                     console.log(res.data);
                  }
               })
               .catch(function (error) {
                  console.log(error);
                  vm.showAlertMethod(
                     "Ocurrio un error intente nuevamente",
                     "warning"
                  );
                  vm.loader.hide()
                  return
               })
            
            //this.editPaymentMt = false;
            this.closeModalAddNewbs.click()
            this.mapJsonAfterEditBS (baData, this.editIndexBS)
            this.showAlertMethod(
               "La cuenta de cobro se modifico correctamente",
               "success"
            )
            vm.loader.hide()
         }
      },
      async createBillStatement(){
         this.sumTotal()
         let vm = this
         vm.v$.$validate()
         if(!vm.accountStatementDataModel.paymentMethod.$error){
            
            vm.loaderfunction(true)
            let baData = 
            {
               "id_alegra": JSON.parse(localStorage.getItem("userJoget"))["id_alegra"],
               "prestador_servicio": this.accountStatementDataModel.name,
               "documento": JSON.parse(localStorage.getItem("userJoget"))["nit"],
               "services": [],
               "observations": this.accountStatementDataModel.observations,
               "total":this.accountStatementDataModel.total,
               "list_payment_method": [
                  {
                     "id": this.accountStatementDataModel.paymentMethod.id
                  }
               ]
            }
            this.services[1].forEach(sv => {
               baData.services.push({
                  "c_cot_number": sv.cotNumber,
                  "c_nombre_ph_empresa": sv.serviceName,
                  "c_c_full_name": sv.fullName,
                  "c_fecha": sv.serviceDate,
                  "c_funcion": sv.function,
                  "c_id_alegra": sv.id_alegra,
                  "service_value": sv.serviceValue,
                  "extra_hours": sv.extraHr
               })
            })
            await axios
               .post(
                  process.env.VUE_APP_API_BASE_URL + "/jw/api/form/cuenta_cobro",
                  baData,
                  {
                     headers: {
                        accept: "application/json",
                        api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                        api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                     },
                  }
               )
               .then(function (res) {
                  if (res.status == 200) {
                     console.log(res.data);
                     
                  }
               })
               .catch(function (error) {
                  console.log(error);
                  this.showAlertMethod(
                     "Ocurrio un error intente nuevamente",
                     "warning"
                  );
                  vm.loader.hide()
                  return;
               });
            await axios
               .get(
                  process.env.VUE_APP_API_BASE_URL +
                  "/jw/api/list/list_cuenta_cobro_api?id_alegra=" +
                  JSON.parse(localStorage.getItem("userJoget"))["id_alegra"],
                  {
                     headers: {
                        accept: "application/json",
                        api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                        api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                     },
                  }
               )
               .then(function (res) {
                  if (res.status == 200) {
                     console.log(res.data);
                     res.data.data.forEach(vm.mapJsonBS);
                     baData.id = res.data.data[res.data.data.length-1].id
                     
                  }
               })
               .catch(function (error) {
                  console.log(error);
               });
            await axios
               .post(
                  process.env.VUE_APP_API_BASE_URL + "/jw/api/form/cuenta_cobro/updateWithFiles?appendFile=true",
                  await vm.saveSig(baData),
                  {
                     params: {
                           'appendFile': 'true'
                     },
                     headers: {
                        accept: "application/json",
                        api_id: "API-06603fc3-af50-4133-bb92-5ba4c3d628d0",
                        api_key: "d989caa8d85c4e46aa34ee6817d22b8c",
                        'Content-Type': 'multipart/form-data'
                     },
                  }
               )
               .then(function (res) {
                  if (res.status == 200) {
                     console.log(res.data);
                  }
               })
               .catch(function (error) {
                  console.log(error);
                  this.showAlertMethod(
                     "Ocurrio un error intente nuevamente",
                     "warning"
                  );
                  vm.loader.hide()
                  return;
               });
            vm.closeModalAddNewbs.click()
            vm.showAlertMethod(
               "La cuenta de cobro se creo correctamente",
               "success"
            );
            vm.createBillSt = false
            vm.loader.hide()
         }
      },
      clearPayMetDataModel() {
         this.editPaymentMt = false
         this.payMetDataModel = {
            pmName: "",
            accNumber: "",
            typeAcc: "",
            bank: "",
            cc: "",
         };
      },
      clearBillStDataModel(){
         this.editBillSt = true
         this.createBillSt = true
         this.clearSig()
         this.disableSig(false)
         this.accountStatementDataModel = {
            id: null,
            name: JSON.parse(localStorage.getItem("habilidades"))["alegra_contacts.full_name"],
            idAlegra: null,
            ccStatus: null,
            dateCreadted: null,
            total: null,
            observations: null,
            paymentMethod: null,
            direccion: null,
            hrValue: 0,
            adjust: 0,
         }
         this.services[1] = []
         this.editIndexBS = null
      },
      clearSig(){
         this.signature1.clear()
      },
      disableSig(state){
         this.sigDisable = state
      },
      async saveSig(frmData){
         const form = new FormData()
         let type = 'image/jpeg'
         let src = this.signature1.save(type)
         console.log(src)
         let name = 'sig_'+ JSON.parse(localStorage.getItem("userJoget"))["id_alegra"]+ "_"+frmData.id+".jpeg"
         let img = await fetch(src)
         let blob = await img.blob()
         let sig = new File([blob], name, {'type':type})
         form.append('id', frmData.id)
         form.append("signature",sig, sig.name)
         form.append('list_payment_method', JSON.stringify(frmData.list_payment_method[0]))
         return form
      },
      searchBank(event) {
         setTimeout(() => {
            if (!event.query.trim().length) {
               this.filteredBanks = [...this.banks];
            } else {
               this.filteredBanks = this.banks.filter((bank) => {
                  return bank.value
                     .toLowerCase()
                     .startsWith(event.query.toLowerCase());
               });
            }
         }, 250);
      },
   },
   components: {
      ArgonButton,
      ArgonBadge,
      ArgonInput,
      AutoComplete,
      Dropdown,
      ArgonAlert,
      PickList,
      Vue3Signature,
   },
};
</script>
