import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlug, faTruck, faDesktop, faVolumeHigh, faUsersRectangle, faCircleQuestion, faFileWord, faTriangleExclamation, faCircleXmark, faCheck, faArrowUp, faTrashCan} from '@fortawesome/free-solid-svg-icons'; //Aqui es donde se le colocan los icons que se van a usar en el sitio
library.add(faPlug, faTruck, faDesktop, faVolumeHigh, faUsersRectangle, faCircleQuestion, faFileWord, faTriangleExclamation,faCircleXmark, faCheck, faArrowUp, faTrashCan); //Aqui es donde se importan
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';



const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(LoadingPlugin);
appInstance.use(PrimeVue,{zIndex: {
    modal: 1100, //dialog, sidebar
    overlay: 1050, //dropdown, overlaypanel
    menu: 1000, //overlay menus
    tooltip: 1100 //tooltip
    }});
appInstance.component("FontAwesomeIcon",FontAwesomeIcon);
appInstance.mount("#app");
