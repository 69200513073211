<template>
  <ArgonAlert v-if="showAlert" @close="resetShowAlert"
    color="info" icon="ni ni-like-2 ni-lg" time="4000" dismissible="true">
    <strong>Confirmado!</strong> Estas confirmado para esta asamblea!
  </ArgonAlert>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <!--  <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.money.title"
              :value="stats.money.value"
              :percentage="stats.money.percentage"
              :iconClass="stats.money.iconClass"
              :iconBackground="stats.money.iconBackground"
              :detail="stats.money.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.users.title"
              :value="stats.users.value"
              :percentage="stats.users.percentage"
              :iconClass="stats.users.iconClass"
              :iconBackground="stats.users.iconBackground"
              :detail="stats.users.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.clients.title"
              :value="stats.clients.value"
              :percentage="stats.clients.percentage"
              :iconClass="stats.clients.iconClass"
              :iconBackground="stats.clients.iconBackground"
              :percentageColor="stats.clients.percentageColor"
              :detail="stats.clients.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.sales.title"
              :value="stats.sales.value"
              :percentage="stats.sales.percentage"
              :iconClass="stats.sales.iconClass"
              :iconBackground="stats.sales.iconBackground"
              :detail="stats.sales.detail"
              directionReverse
            ></card>
          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-7 mb-4">
            <!-- line chart -->
            <div class="card z-index-2">
              <div class="card" style="width: full;">
                <div class="col-md-3 card-header">
                  <img :src="URL" class="img-fluid rounded-start">
                </div>
                <div class="card-body">
                  <h5 class="card-title ">{{ userJoget.name }}</h5>
                  <p class="card-text">Documento: {{ userJoget['nit'] }}</p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Correo: {{ userJoget['email'] }}</li>
                  <li class="list-group-item">Celular: {{ userJoget['phone'] }}</li>
                  <li class="list-group-item">Direccion: {{ userJoget['address'] }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <AvailabilityCard @add="showScheduleModal = true" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-3">Eventos Agendados</h6>
                </div>
              </div>
              <div>
                <!-- Modal CofirmarAsistencia -->
                <div class="modal fade" id="cofirmarAsistencia" aria-hidden="true" aria-labelledby="cofirmarAsistenciaLabel" tabindex="-1">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        
                        <h1 class="modal-title fs-5" id="cofirmarAsistenciaLabel">Leer Con atención</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <DefaultInfoCard 
                        title="¡Advertencia!"
                        desc="Al presionar Confirmar estas aceptando atender el evento. Una vez confirmado si deseas cancelar debes colocarte en contacto con el Área de Talento Humano."
                        classIcon="fa-solid fa-triangle-exclamation"></DefaultInfoCard>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <ArgonButton color="success" type="button" class="col-6 btn btn-secondary" @click="confirmarAsistencia" data-bs-toggle="modal" >Confirmar</ArgonButton>
                        <ArgonButton color="warning" class="btn btn-primary" data-bs-target="#verMas" data-bs-toggle="modal">Atras</ArgonButton>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Modal Ver mas -->
                <div class="modal fade" id="verMas" tabindex="-1" aria-labelledby="verMaslLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title" id="verMasLabel">
                          Detalles del Servicio
                        </h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div class="a">
                          <div><b>Cotización:</b> {{ elementosAsamblea.id_cot }}</div>
                          <div><b>Administrador:</b> {{ elementosAsamblea['sol_cot_client.nombre_admin'] }}</div>
                          <div><b>Unidad:</b> {{ elementosAsamblea['sol_cot_client.nombre_ph_empresa'] }}</div>
                          <div><b>Dirección:</b> {{ elementosAsamblea['sol_cot_service.direccion_lugar_del_servicio'] }}
                          </div>
                          <div><b>Municipio:</b> {{ elementosAsamblea['sol_cot_service.municipio'] }}</div>
                          <div><b>Telefono Porteria:</b> {{ elementosAsamblea['sol_cot_client.telefono_porteria'] }}
                          </div>
                          <div><b>Participantes:</b> {{ elementosAsamblea['sol_cot_service.cantidad_participantes'] }}
                          </div>
                          <div><b>Tipo Servicio:</b> {{ elementosAsamblea['sol_cot_service.eleccion_servicio'] }}</div>
                          <div><b>Primer Convocatoria:</b> {{
                              elementosAsamblea['sol_cot_service.fecha_primera_convocatoria']
                          }}</div>
                          <div><b>Segunda Convocatria:</b> {{
                              elementosAsamblea['sol_cot_service.fecha_segunda_convocatoria']
                          }}</div>
                          <div><b>En esta asamblea te acompañaran:</b></div>
                        </div>
                        <PrestadoresTable :data=this.elementosAsamblea.equipo> </PrestadoresTable>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <ArgonButton v-if="showConfirmAtendance()" color="warning" type="button" class="col-6 btn btn-secondary" data-bs-target=#cofirmarAsistencia data-bs-toggle="modal" >Confirmar Asistencia</ArgonButton>
                        <ArgonButton color="success" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</ArgonButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-2 container">
                  <div class="row justify-content-center">
                    <div class="col-2 col-sm-auto col-md-auto">
                      <button class="btn btn-success" size="sm" @click="changeWeek('prev')"><i
                          class="fas fa-chevron-left"></i></button>
                    </div>
                    <div class="col-auto col-sm-auto col-md-auto">
                      <button size="sm" class="btn btn-success" variant="outline-secondary">{{ this.startMonth }}
                        {{ this.date.weekStart.day }} - {{ this.endMonth }} {{ this.date.weekEnd.day }},
                        {{ this.weekDate.year }}</button>
                    </div>
                    <div class="col-2 col-sm-auto col-md-auto">
                      <button class="btn btn-success" size="sm" @click="changeWeek('next')"><i
                          class="fas fa-chevron-right"></i></button>
                    </div>
                  </div>
                </div>
                <div>
                  <div v-if="!weekloaded">
                    <CalendarLoader></CalendarLoader>
                  </div>
                  <div class="mt-4 d-flex justify-content-center" v-if="weekloaded">
                    <h5 style="text-align: center;" v-if="!weekBookingLenght" >No tienes Asambleas asignadas esta semana :)</h5>
                  </div>
                  <div v-if="weekloaded">
                    <div class="col-md-12 track pl-0 pr-0" style="min-height:50vh" v-if="weekBookingLenght">
                      <div class="card" title="Full Body" id="foo" bodyClass="task-body" headerClass="header-small"
                        v-for="(item, index) in boards" :key="index">
                        <div class="card-body">
                          <h6 class="text-black font-size-12"> {{ item.title }} {{ item.date }} </h6>
                          <i v-for="(element, index) in item.list" :key="element.confirm + index"
                          @click="viewAssemblieInfo(element)" class="hoverSpan">
                            <div class="card" bodyClass="task-card" headerClass="task-card-title">
                              <div class="card-body">
                                <a class="card-title text-muted">{{ element["sol_cot_client.nombre_ph_empresa"] }}</a>
                                <div>
                                  <p class="font-size-11 mb-0">
                                    <span>{{ element.cot_number }}</span><br>
                                    <i class="fas fa-users fa-xs mr-1"></i>
                                    <span class="mr-1">Fecha: {{ element.fecha }}</span><br>
                                    <i class="fas fa-clock fa-xs mr-1"></i>
                                    <span>Hora: {{ element.hora }}</span> <br>
                                    <span>Operario Confirmado: {{ element.confirm }}</span>
                                  </p>
                                  <p style="min-height: 43px" class="font-size-12">
                                    Función: {{ element.funcion }}
                                  </p>
                                  <div class="d-flex justify-content-between">
                                  </div>
                                </div>
                                <div :value="100" class="iq-border-radius-10" :variant="element.funcion" label=" " />
                                <!-- Button trigger modal -->
                                <ArgonButton color="success" type="button" class="btn btn-primary" data-bs-toggle="modal"
                                  data-bs-target="#verMas">
                                  Ver Información
                                </ArgonButton>
                              </div>
                            </div>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <categories-card />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Card from "@/examples/Cards/Card.vue";
import CategoriesCard from "./components/CategoriesCard.vue";
import AvailabilityCard from "./components/AvailabilityCard.vue"
import axios from 'axios'
import DefaultInfoCard from "../examples/Cards/DefaultInfoCard.vue";
import PrestadoresTable from "./components/PrestadoresTable.vue";
const { DateTime } = require('luxon')

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import CalendarLoader from "../components/Loaders/CalendarLoader.vue";
import ArgonButton from "../components/ArgonButton.vue";
import ArgonAlert from "../components/ArgonAlert.vue";



export default {
  name: "dashboard-default",
  data() {
    return {
      weekloaded: false,
      weekBookingLenght: false,
      showAlert: false,
      stats: {
        money: {
          title: "Servicios Agendados",
          value: "$53,000",
          percentage: "+55%",
          iconClass: "ni ni-bell-55",
          detail: "since yesterday",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Servicios para hoy",
          value: "2,300",
          percentage: "+3%",
          iconClass: "ni ni-world",
          iconBackground: "bg-gradient-danger",
          detail: "since last week",
        },
        clients: {
          title: "Servicios finalizados",
          value: "+3,462",
          percentage: "-2%",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "since last quarter",
        },
        sales: {
          title: "Total Cobrado",
          value: "$103,430",
          percentage: "+5%",
          iconClass: "ni ni-cart",
          iconBackground: "bg-gradient-warning",
          detail: "than last month",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
      weekDate: DateTime.now().c,
      date: {
        day: 0,
        weekStart: {},
        weekEnd: {},
        today: {
          day: 0,
          month: 0,
          year: 0
        },
        weekOfYear: 0
      },
      boards: [
        {
          id: 1,
          title: 'Lunes',
          date: '',
          color: 'bg-primary',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 2,
          title: 'Martes',
          date: '',
          color: 'bg-success',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 3,
          title: 'Miércoles',
          date: '',
          color: 'bg-info',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: [
          ]
        },
        {
          id: 4,
          title: 'Jueves',
          date: '',
          color: 'bg-warning',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 5,
          title: 'Viernes',
          date: '',
          color: 'bg-danger',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 6,
          title: 'Sábado',
          date: '',
          color: 'bg-danger',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        },
        {
          id: 7,
          title: 'Domingo',
          date: '',
          color: 'bg-danger',
          available: {
            presencial: 10,
            virtual: 20,
            mixta: 10,
            cotizacion: 0
          },
          list: []
        }
      ],
      userJoget: JSON.parse(localStorage.getItem('userJoget')),
      rowDataCard: null,
      months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      user: {
        staff: {},
        address: {
          address: '',
          city: '',
          country: '',
          department: '',
          neighborhood: '',
          sector: ''
        },
        type: '',
        name: '',
        nameAdmin: '',
        email: '',
        url: '',
        phonePrimary: '',
        identificationObject: {
          number: '',
          type: '',
          dv: ''
        },
        profile_image: ''
      },
      tiempos: '',
      agenda_operarios: [],
      URL: localStorage.getItem('URL'),
      elementosAsamblea: {
      }
    };
  },
  mounted() {
    this.getWeekDates(this.weekDate)
  },
  watch: {
    rowDataCard(newValue) {
      if (newValue.length >= 1) {
        this.getWeekDates(this.weekDate)
      } else {
        this.$emit('load-success', { type: false })
      }
    },
    prueba(newValue) {
      console.log('prueba', newValue)
      if (this.rowDataCard < 1) {
        this.getWeekDates(this.weekDate)
      }
    }
  },
  computed: {
    startMonth() {
      return this.months[this.date.weekStart.month - 1]
    },
    endMonth() {
      return this.months[this.date.weekEnd.month - 1]
    },
    formatedDate() {
      return this.formatDate(this.weekDate)
    },
  },
  methods: {
    resetShowAlert(varAlert){
      this.showAlert = varAlert
    },
    async changeWeek(type) {
      this.weekloaded = false
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
        this.boards[index2].available = {
          presencial: 10,
          virtual: 20,
          mixta: 10,
          cotizacion: 0
        }
      }
      console.log('listas vacias')
      if (type === 'next') {
        this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).plus({ days: 7 }).c
        console.log('add', this.weekDate)
        this.getWeekDates(this.weekDate)
      }
      if (type === 'prev') {
        this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).minus({ days: 7 }).c
        this.getWeekDates(this.weekDate)
        console.log('minus', this.weekDate)
      }
    },
    async getWeekDates(myDate) {
      // console.log('boards lenght', this.boards.length)
      var habilidades = JSON.parse(localStorage.getItem('habilidades'))
      this.userJoget.name = habilidades['alegra_contacts.full_name']
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
        // console.log('boards lenght', this.boards)
        this.boards[index2].available = {
          presencial: 10,
          virtual: 20,
          mixta: 10,
          cotizacion: 0
        }
        this.dates = []
      }
      this.date.today.day = DateTime.now.day
      this.date.today.month = DateTime.now.month
      this.date.today.year = DateTime.now.year
      this.date.weekStart = await DateTime.local(myDate.year, myDate.month, myDate.day).startOf('week').c
      this.date.weekEnd = DateTime.local(myDate.year, myDate.month, myDate.day).endOf('week').c
      this.date.weekOfYear = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).weekNumber
      for (let index = 0; index < this.boards.length; index++) {
        this.boards[index].date = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day
      }
      if (this.rowDataCard !== null) { this.getCardsInfo() }
      this.providersDatesInit(this.date.weekOfYear)
      this.agendaOperarios()
    },
    getCardsInfo() {
      // console.log('trayendo los datos', this.rowDataCard.length)
      for (let index = 0; index < this.rowDataCard.length; index++) {
        var splitedDate
        if (this.rowDataCard[index].date !== undefined || this.rowDataCard[index].date !== null) {
          splitedDate = this.rowDataCard[index].date.split('-')
        }
        const year = parseFloat(splitedDate[0])
        const month = parseFloat(splitedDate[1])
        const day = parseFloat(splitedDate[2])
        const cardDay = DateTime.local(year, month, day).ordinal
        const startDate = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).ordinal
        const endDate = DateTime.local(this.date.weekEnd.year, this.date.weekEnd.month, this.date.weekEnd.day).ordinal
        if (cardDay >= startDate && cardDay <= endDate) {
          // console.log('Its between')
          for (let index2 = 0; index2 < this.boards.length; index2++) {
            if (this.boards[index2].id === this.rowDataCard[index].boardId) {
              // console.log('Is ths board', this.boards[index2].id)
              const filters = this.filters
              if (filters[this.rowDataCard[index].type] === true) {
                const typeOf = this.rowDataCard[index].type
                if (typeOf === 'virtual') {
                  this.boards[index2].available[typeOf] -= 1
                }
                if (typeOf === 'presencial') {
                  this.boards[index2].available.presencial -= 1
                }
                if (typeOf === 'mixta') {
                  this.boards[index2].available.presencial -= 1
                  this.boards[index2].available.virtual -= 1
                }
                if (typeOf === 'cotizacion') {
                  this.boards[index2].available.cotizacion += 1
                }
                // console.log('antes de', this.boards[index2].available[typeOf])
                // console.log('despues de', this.boards[index2].available[typeOf])
                this.boards[index2].list.push(this.rowDataCard[index])
              }
            }
          }
          this.$emit('load-success', { type: false })
        } else { this.$emit('load-success', { type: false }) }
      }
    },
    providersDatesInit(weekNumber) {
      console.log('entré al init', this.user.schedule)
      if (this.user.schedule != null) {
        console.log('tiene schedule')
        if (this.user.schedule[`week${weekNumber}`] !== null && this.user.schedule[`week${weekNumber}`] !== undefined) {
          console.log('tiene semana', weekNumber)
          this.userAvailability = this.user.schedule[`week${weekNumber}`]
          console.log('disponibilidad', this.userAvailability, this.date.weekOfYear)
          for (let index = 0; index < 7; index++) {
            var variableIndex = index + 1
            this.dates.push({
              fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
              date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
              start: this.userAvailability[variableIndex].start,
              available: this.userAvailability[variableIndex].available,
              editable: false,
              hasPrevValue: true,
              totalServices: this.userAvailability[variableIndex].totalServices,
              totalUsers: this.userAvailability[variableIndex].totalUsers
            })
          }
        } else {
          for (let index = 0; index < 7; index++) {
            this.dates.push({
              fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
              date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
              start: '',
              available: false,
              editable: false,
              hasPrevValue: false,
              totalServices: 0,
              totalUsers: 0
            })
          }
          console.log('No tiene Semana', weekNumber)
        }
      } else {
        for (let index = 0; index < 7; index++) {
          this.dates.push({
            fullDate: DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c,
            date: this.days[index] + ' ' + DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).plus({ days: index }).c.day,
            start: '',
            available: false,
            editable: false,
            hasPrevValue: false,
            totalServices: 0,
            totalUsers: 0
          })
        }
        console.log('No tiene Schedule', weekNumber)
      }
    },
    async agendaOperarios() {
      const vm = this
      vm.actualizarFechas()
      var newday
      var newmonth
      console.log('Dia Inicio', vm.date.weekStart.day)
      console.log('Mes Inicio', vm.date.weekStart.month)
      console.log('Año Inicio', vm.date.weekStart.year)
      if (vm.date.weekStart.day < 10) {
        newday = '0' + vm.date.weekStart.day
      } else {
        newday = vm.date.weekStart.day
      }
      if (vm.date.weekStart.month < 10) {
        newmonth = '0' + vm.date.weekStart.month
      } else {
        newmonth = vm.date.weekStart.month
      }
      var fecha1 = newmonth + '/' + newday + '/' + vm.date.weekStart.year
      console.log('fecha1', fecha1)
      for (let index = 0; index < 7; index++) {
        var fecha2 = new Date(fecha1)
        console.log('fecha2_primero', fecha2)
        fecha2.setDate(fecha2.getDate() + index)
        var day = `0${fecha2.getDate()}`.slice(-2)
        var month = `0${fecha2.getMonth() + 1}`.slice(-2)
        var resultado = day + '-' + month + '-' + fecha2.getFullYear()
        console.log('fecha2', resultado)
        console.log('nombre', vm.userJoget)
        await axios.get(process.env.VUE_APP_API_BASE_URL + '/jw/api/list/1602_agenda_operarios_api?fecha=' + resultado + '&c_full_name=' + vm.userJoget.name, {
          responseType: 'json',
          headers: {
            accept: 'application/json',
            api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
            api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
          }
        })
          .then(function (res) {
            console.log('respuesta Agenda Operarios', res.data.data[0])
            vm.agenda_operarios[index] = {
              c_full_name: '',
              cot_number: '',
              fecha: '',
              funcion: '',
              hora: '',
              confirm: '',
            }
            if (res.data.data[0]) {
              vm.agenda_operarios[index] = res.data.data[0]
              vm.boards[index].list.push(res.data.data[0])
            } else {
              vm.agenda_operarios[index] = {}
            }
            // vm.userJoget['alegra_contacts.phone'] = vm.user.phonePrimary loader aqui
            // vm.userJoget['alegra_contacts.address'] = vm.user.address.address
            // vm.makeToast('success', 'guardado exitoso')
          })
          .catch(function (err) {
            console.log('error de almacenaje', err)
            // vm.makeToast('danger', 'Problemas al guardar.')
          })
      }
      vm.consultWeekBookingLenght()
      vm.weekloaded = true
    },
    async actualizarFechas() {
      const vm = this
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/jw/api/list/023_disponibilidad2?id_alegra=' + this.userJoget['id_alegra'], {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          console.log('respuesta tiempos', res.data)
          if (res.status === 200) {
            // core.showSnackbar('true', 'comnsulta exitosa')
            vm.tiempos = res.data.data
          }
        })
        .catch(function (err) {
          console.log('error de consulta', err)
        })
    },
    async viewAssemblieInfo(assemblie) {
      console.log('info de la asamblea', assemblie.cot_number)
      this.currentAssemblie = assemblie
      this.assemblieInfo = true
      const vm = this
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/jw/api/list/9_1_axios_asambleas?id_cot=' + assemblie.cot_number, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          console.log('respuesta asamblea', res.data)
          if (res.status === 200) {
            // core.showSnackbar('true', 'comnsulta exitosa')
            vm.elementosAsamblea = res.data.data[0]
            console.log('respuesta Satisfactoria', res.data.data[0])
          }
        })
        .catch(function (err) {
          console.log('error de consulta', err)
        })
        await axios.get(process.env.VUE_APP_API_BASE_URL + '/jw/api/list/temporal_api?cot_number=' + assemblie.cot_number, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            vm.elementosAsamblea.equipo = res.data.data
          }
        })
        .catch(function (err) {
          console.log('error de consulta', err)
        })
    },
    consultWeekBookingLenght() {
      var vm = this
      var sum = 0
      vm.boards.forEach(list => {
        sum +=list.list.length
      })
      if (sum > 0) {
          return vm.weekBookingLenght = true
        }
      vm.weekBookingLenght = false
      return
    },
    async confirmarAsistencia(){
      try{
        var record_id = await this.getTempId()
        await this.updateTempConfirm(record_id)
      }catch (err){
        console.log(err)
      }
      
    },
    async getTempId(){
      var nameOperator
      this.boards.forEach(brd => {
        brd.list.forEach(lst =>{
          if(lst.cot_number == this.elementosAsamblea.id_cot){
            nameOperator = lst.c_full_name
          }
        })
      })
      return await axios.get(process.env.VUE_APP_API_BASE_URL + '/jw/api/list/temporal_api?c_full_name='+nameOperator+'&cot_number='+this.elementosAsamblea.id_cot, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          if (res.status === 200) {            
            return res.data.data[0].id
          }
        })
        .catch(function (err) {
          console.log('error de consulta', err)
          return err
        })
    },
    async updateTempConfirm(record_id){
      var vm = this
      var payLoad = {
        "id": record_id,
        "confirm": "Si"
      }
      return await axios.put(process.env.VUE_APP_API_BASE_URL + '/jw/api/form/temporal', payLoad, {
        responseType: 'json',
        headers: {
          accept: 'application/json',
          api_id: 'API-06603fc3-af50-4133-bb92-5ba4c3d628d0',
          api_key: 'd989caa8d85c4e46aa34ee6817d22b8c'
        }
      })
        .then(function (res) {
          if (res.status === 200) {
            vm.boards.forEach(brd => {
              brd.list.forEach(lst =>{
                if(lst.cot_number == vm.elementosAsamblea.id_cot){
                  lst.confirm = 'Si'
                  vm.showAlert = true
                }
              })
            })            
            return res.data
          }
        })
        .catch(function (err) {
          console.log('error de consulta', err)
          return err
        })
    },
    showConfirmAtendance(){
      var res = true
      this.boards.forEach(brd => {
        brd.list.forEach(lst =>{
          if(lst.cot_number == this.elementosAsamblea.id_cot){
            res = (lst.confirm == 'Si' ? false : true)
          }
        })
      })
      return res
    },
  },
  components: {
    // Card,
    CategoriesCard,
    AvailabilityCard,
    CalendarLoader,
    ArgonButton,
    DefaultInfoCard,
    ArgonAlert,
    PrestadoresTable,
},
};
</script>
