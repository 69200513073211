<template v-if>
  <div v-if="this.showAlert"
    class="alert text-white font-weight-bold "
    role="alert"
    :class="getClasses(color, dismissible)"
  >
    <span class="alert-icon">
      <i :class="getIcon(icon)" />
    </span>
    <span class="alert-text">
      &nbsp;
      <slot />
    </span>
    <button
      v-if="dismissible"
      type="button"
      class="btn-close d-flex justify-content-center align-items-center"
      data-bs-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true" class="text-lg font-weight-bold">&times;</span>
    </button>
  </div>
</template>

<script>

export default {
  name: "argon-alert",
  data(){
    return{
      showAlert: true
    }
  },
  props: {
    color: {
      type: String,
      default: "success",
    },
    time:{
      type: Number,
      default: 3000,
    },
    icon: String,
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
     var self = this
     setTimeout(function() {
       self.showAlert = false
       self.$emit('close', self.showAlert)
     }, self.time)
  },
  methods: {
    getClasses: (color, dismissible) => {
      let colorValue, dismissibleValue;

      colorValue = color ? `alert-${color}` : null;

      dismissibleValue = dismissible ? "alert-dismissible fade show" : null;

      return `${colorValue} ${dismissibleValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    getShow: () => {
      return 
    }
  },
};
</script>
