<template>
    <content-loader
      viewBox="0 0 425 355"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="15" y="100" rx="5" ry="5" width="220" height="18" /> 
      <rect x="270" y="20" rx="8" ry="8" width="126" height="34" /> 
      <rect x="15" y="29" rx="5" ry="5" width="178" height="19" /> 
      <rect x="16" y="135" rx="5" ry="5" width="220" height="18" /> 
      <rect x="16" y="170" rx="5" ry="5" width="220" height="18" /> 
      <rect x="16" y="205" rx="5" ry="5" width="220" height="18" /> 
      <rect x="16" y="240" rx="5" ry="5" width="220" height="18" /> 
      <rect x="16" y="275" rx="5" ry="5" width="220" height="18" /> 
      <rect x="16" y="310" rx="5" ry="5" width="220" height="18" />
    </content-loader>
  </template>

<script>
  import { ContentLoader } from "vue-content-loader"

  export default {
    components: { ContentLoader }
  }
</script>