import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
//import Tables from "../views/Tables.vue";
import BillingStatement from "../views/BillingStatement.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Informes from "../views/Informes.vue"


const routes = [
  {
    path: "/",
    name: "/",
    meta: { },
    redirect: "/Signin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { auth: true },
    component: Dashboard,
  },
  {
    path: "/informes",
    name: "Informes",
    meta: { auth: true },
    component: Informes,
  },
  {
    path: "/billingstatement",
    name: "billingstatement",
    meta: { auth: true },
    component: BillingStatement,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    meta: { auth: true },
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    meta: { auth: true },
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { auth: true },
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    meta: { auth: true },
    component: Signup,
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/Signin', '/auth/sign-up1', '/dark/Signin', '/dark/auth/sign-up1']
  if (publicPages.includes(to.path)) {
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
  }
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (to.meta.auth) {
    if (authRequired && loggedIn === null) {
      return next('/Signin')
    } else if (to.name === 'dashboard') {
      return next('/admin-dashboard/home')
    }
    next()
  } else {
    if (to.meta.authSuperAdmins) {
      if (to.path === '/sv-admin-clase-alpha/sing-in') {
        if (localStorage.getItem('user') === null) {
          next()
        } else {
          return next('/sv-admin-clase-alpha/super-admin/dashboard')
        }
      } else {
        if (to.path !== '/sv-admin-clase-alpha/sing-in' && localStorage.getItem('user') === null) {
          return next('/sv-admin-clase-alpha/sing-in')
        } else {
          next()
        }
      }
    } else {
      if (to.meta.authUser) {
        if (authRequired && loggedIn === null) {
          return next('/Signin')
        } else if (to.name === 'dashboard') {
          return next('/user-dashboard/home')
        }
      }
    }
  }
  next()
})

export default router;
